import { PartialThread } from '@/types';
import React from 'react';

import { useThreadListQuery } from '../../useThreadListQuery';
import { Placeholder } from '../placeholder';
import { GroupedThreadList } from './grouped-thread-list';

interface ThreadListProps {
  activeThreadId?: string;
  errorComponent?: React.ElementType;
  successComponent?: React.ElementType<{ activeThreadId?: string, threads: PartialThread[] }>;
  loadingComponent?: React.ElementType;
}

interface ListSuccessComponentProps {
  activeThreadId?: string;
  threads: PartialThread[];
}

function ThreadListSuccessComponent(props: ListSuccessComponentProps) {
  const { threads, activeThreadId } = props;

  return (
    <GroupedThreadList threads={threads} activeThreadId={activeThreadId} />
  );
}

function ThreadListErrorComponent() {
  return (
    <div className="w-full mx-auto">
      <p>Something went wrong</p>
    </div>
  );
}

function ThreadListLoadingComponent() {
  return (
    <div className="w-full px-4 mt-4">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <Placeholder count={10}>
            <div className="rounded-sm h-4 m-4 bg-slate-200" />
          </Placeholder>
        </div>
      </div>
    </div>
  );
}

export function ThreadList(props: ThreadListProps) {
  const {
    activeThreadId,
    successComponent: SuccessComponent = ThreadListSuccessComponent,
    errorComponent: ErrorComponent = ThreadListErrorComponent,
    loadingComponent: LoadingComponent = ThreadListLoadingComponent
  } = props;

  const { status, data } = useThreadListQuery();

  if (status === 'pending') {
    return (
      <LoadingComponent />
    );
  }

  if (status === 'error' || data === undefined) {
    return (
      <ErrorComponent />
    );
  }

  return (
    <SuccessComponent threads={data} activeThreadId={activeThreadId} />
  )
}
