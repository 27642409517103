import React, { useCallback, useEffect, useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useThreadDeleteQuery } from '../../useThreadDeleteQuery';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { useThreadUpdateQuery } from '../../useThreadUpdateQuery';
import { ConfirmDelete } from '../confirm-delete';
import { Button } from '../../../../components/button';
import { useMobileHeaderContext } from '../../contexts/MobileHeaderContext';
import ThreadTopicBadge from './thread-topic-badge';
import EditThreadView from './edit-thread-view';

type OnDeleteFunction = (id: string) => void;

interface ThreadItemProps {
  active: boolean;
  id: string;
  title: string;
  topic: string;
  onDelete: OnDeleteFunction;
}

export default function ThreadItem(props: ThreadItemProps) {
  const { active, id, title, topic, onDelete } = props;
  const { deleteThread } = useThreadDeleteQuery();
  const { updateTitle } = useThreadUpdateQuery(id);
  const { trackThreadDeleted, trackThreadRenamed } = useAnalyticsEvent();
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const { toggleThreadMenuVisibility, threadMenuVisible } = useMobileHeaderContext();

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  const editTitle = useCallback(() => {
    updateTitle(newTitle).then(() => {
      setIsEditing(false);
      trackThreadRenamed(id);
    });
  }, [id, newTitle, updateTitle, trackThreadRenamed]);

  const handleEditClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setIsEditing(true);
  }, [id]);

  const handleEditBlur = useCallback(() => {
    if (newTitle !== title) {
      editTitle();
    }

    setIsEditing(false);
  }, [id, newTitle, title, editTitle]);

  const handleEditKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Escape') {
      setIsEditing(false);
      setNewTitle(title);
    }

    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      editTitle();
    }
  }, [id, newTitle, title, editTitle]);

  const handleDeleteItem = useCallback(() => {
    trackThreadDeleted(id);
    void deleteThread(id);
    onDelete(id);
  }, [id, onDelete, deleteThread, trackThreadDeleted]);

  const handleEditChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewTitle(event.target.value);
  }, [id, newTitle]);

  return !isEditing ? (
    <>
      <Link
        className="block px-4 md:px-10 lg:px-4 py-4"
        to={`/${id}`}
        onClick={() => {threadMenuVisible && toggleThreadMenuVisibility()}}
      >
        <div className="flex">
          <div className='grid grid-cols-1 w-5/6'>
            <div className="row-start-1 col-start-1 flex justify-between">
              <div className="flex-1 ml-2">{newTitle}</div>
            </div>
          </div>
        </div>
        <ThreadTopicBadge active={active} topic={topic} />
      </Link>
      <div className="row-start-1 col-start-1 flex justify-between absolute right-2 top-4">
        <div className="flex flex-row gap-1 lg:gap-0 transition duration-0">
          <Button
            className="w-6 h-6 lg:opacity-0 lg:group-hover:opacity-100 focus:opacity-100"
            colour="secondary"
            title={`Rename Thread "${title}"`}
            onClick={handleEditClick}
            variant="icon"
          >
            <PencilSquareIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block"/>
          </Button>
          <ConfirmDelete
            onConfirm={handleDeleteItem}
            title={`Delete Thread "${title}"`}
            prompt="Are you sure you want to delete this thread?"
            deleteButtonTitle={`Delete Thread "${title}"`}
          >
            <div className="text-grey-600 hover:text-blue-400">
              <TrashIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block" aria-hidden={true}/>
            </div>
          </ConfirmDelete>
        </div>
      </div>
    </>
  )
  : (
    <div className="block px-4 md:px-10 lg:px-4 py-4">
      <div className="flex">
        <div className='grid grid-cols-1 w-full'>
          <EditThreadView
            newTitle={newTitle}
            onBlur={handleEditBlur}
            onKeyDown={handleEditKeyDown}
            onChange={handleEditChange}
          />
        </div>
      </div>
      <ThreadTopicBadge active={active} topic={topic} />
    </div>
  );
}
