import { SourceData } from "@/types";
import React from "react";

export function SourcePublished({ data }: { data: SourceData }) {
  if (data.type === 'document') {
    return (
      <div className="text-sm mb-2">Published: {data.metadata.datePublished}</div>
    );
  }

  return null;
}
