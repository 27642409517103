import { SourceView } from '@/types';

type TypeAndTitle = {
  displayType: SourceView['displayType'];
  contentType?: SourceView['contentType'];
  title: string;
}
export const sortSourceByDisplayType = (a: TypeAndTitle, b: TypeAndTitle) => {
  const order = ['statute', 'regulation', 'hmrc', 'admin', 'case', 'commentary', 'unknown'];
  return order.indexOf(a.displayType) - order.indexOf(b.displayType);
};
