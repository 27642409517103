import { CaseMeta, SourceData } from '@/types';
import { withErrorBoundary } from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { AskDocInteractionType, AskDocPayload } from '../../ask-bluej/streaming/doc/shared';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { AUTO_ASK_TRANSITION_DELAY, QUICK_SUMMARIZE_QUESTION, QUICK_SUMMARIZE_QUESTION_DECISION } from '../constants';
import { extractTextFromHtml } from '../extract';
import { AskDocQuestionInitiator } from './doc-ask-question-initiator';
import { DocQuestionInput } from './doc-question-input';

export const AskDocument = withErrorBoundary((props: Props) => {
  const { interactionContext, content, sourceData, setIsQuickSummarize, docQuestionTooltip, additionalSources } = props;
  const { trackDocQuestionAsked, trackDocQuestionRetryClicked } = useAnalyticsEvent();

  const presetQuestion = getPresetQuestion(interactionContext, sourceData.title, sourceData.type, sourceData.metadata, sourceData.detailsTableData);

  const [questionPayload, setQuestionPayload] = useState<null | AskDocPayload>(null);

  const questionKey = JSON.stringify(questionPayload); // Used to force the initiator to rerender whenever the payload changes

  const handleSubmit = useCallback(async (question: string, isRetry = false) => {
    const content = extractTextFromHtml(props.content);
    setQuestionPayload({ question, content, guid: uuid(), isRetry });
    const analyticsPayload = {
      interactionContext,
      questionCharacterCount: question.length,
      sourceType: sourceData.type,
      sourceTitle: sourceData.title,
      sourceId: sourceData.id,
      additionalSources
    }
    isRetry && trackDocQuestionRetryClicked(analyticsPayload);
    trackDocQuestionAsked(analyticsPayload);
    return true;
  }, [content, interactionContext, sourceData]);

  const handleOnClose = useCallback(() => {
    setQuestionPayload(null);
    // TODO: this now seems restrictive - we should be able to have many different templated questions
    setIsQuickSummarize && setIsQuickSummarize(false);
  }, []);

  useEffect(() => {
    if (presetQuestion.autoAsk) {
      const timeout = setTimeout(() => {
        void handleSubmit(presetQuestion.question, false)
      }, AUTO_ASK_TRANSITION_DELAY);
      return () => clearTimeout(timeout);
    }
  }, []);

  if (extractTextFromHtml(content) === '' || sourceData.externalUrl) {
    return null;
  }

  return (
    <>
      {questionPayload &&
        <AskDocQuestionInitiator
          key={questionKey}
          payload={questionPayload}
          onClose={handleOnClose}
          interactionContext={interactionContext}
          sourceData={sourceData}
          onResubmit={handleSubmit}
          displayQuestion={presetQuestion.displayQuestion || questionPayload.question}
          additionalSources={additionalSources}
        />
      }
      <div>
        <DocQuestionInput
          key={presetQuestion.question}
          disabled={presetQuestion.autoAsk}
          defaultInput={presetQuestion.displayQuestion}
          onSubmit={handleSubmit}
          interactionContext={interactionContext}
          docQuestionTooltip={docQuestionTooltip}/>
      </div>
    </>
  )
}, {});

type Props = {
  interactionContext: AskDocInteractionType;
  sourceData: SourceData;
  content: string;
  setIsQuickSummarize?: (isQuickSummarize: boolean) => void;
  docQuestionTooltip: {
    isTooltipVisible: boolean;
    setTooltipVisible: (visible: boolean) => void;
  };
  additionalSources?: boolean;
}

type PresetQuestion = { question: string, displayQuestion?: string, autoAsk: boolean };

const DECISION_DOCS = ['IRS Revenue Ruling', 'IRS Technical Advice Memorandum', 'IRS Private Letter Ruling', 'IRS Field Service Advice', 'IRS Field Service Advisory Memorandum'];

function getQuickSummarizePresetQuestion(title: string, docType: string, metadata: SourceData['metadata'], detailsTableData?: SourceData['detailsTableData']): PresetQuestion {
  let question = QUICK_SUMMARIZE_QUESTION;
  let displayQuestion = `Summary: ${title}`;
  // TODO (backwards-compat)
  if (docType === 'case' || docType === 'curator_case') {
    const citations = (metadata as CaseMeta).citations ?? detailsTableData?.citation;
    displayQuestion += `, ${citations}`;
    question = QUICK_SUMMARIZE_QUESTION_DECISION;
    // TODO (backwards-compat)
  } else if (docType === 'document') {
    const documentType = String(detailsTableData?.type);

    if (documentType && DECISION_DOCS.includes(documentType)) {
      question = QUICK_SUMMARIZE_QUESTION_DECISION;
    }
  } else if (docType === 'curator_document') {
    const documentType = detailsTableData?.type as string;

    if (documentType && DECISION_DOCS.includes(documentType)) {
      question = QUICK_SUMMARIZE_QUESTION_DECISION;
    }
  }
  return { question, displayQuestion, autoAsk: true };
}

function getPresetQuestion(type: AskDocInteractionType, title: string, docType: string, metadata: SourceData['metadata'], detailsTableData?: SourceData['detailsTableData']) {
  switch (type) {
    case AskDocInteractionType.QUICK_SUMMARIZE:
      return getQuickSummarizePresetQuestion(title, docType, metadata, detailsTableData);
    default:
      return { question: '', autoAsk: false };
  }
}
