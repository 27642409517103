import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useSourceApiContext } from './contexts/SourceApiContext';
import { SourceData } from '@/types';

export type SourceContentQueryProps = {
  type: string;
  id: string;
  titlePrefix?: string;
  displayType?: string;
}

export function useSourceContentQuery(source: SourceContentQueryProps): UseQueryResult<SourceData> {
  const { api } = useSourceApiContext();
  return useQuery({
    queryKey: ['sources', source.type, source.id],
    queryFn: async () => {
      // remove trailing hash from id
      const strippedId = source.id.split('#')[0];
      return api.getSourceContent(strippedId, source.type).then((response) => {
        response.title = (source.titlePrefix ?? '') + response.title;
        // FIXME: Currently using strippedId since content-management is returning a uuid incompatible with FE
        return { ...response, id: strippedId };
      });
    },
    staleTime: Infinity,
    gcTime: Infinity
  });
}
