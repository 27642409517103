import isAbsoluteUrl from 'is-absolute-url';
import { Node, Parent } from 'unist';
import { visit } from 'unist-util-visit';

interface AnchorElement extends Parent {
  type: 'element';
  tagName: 'a';
  properties: {
    href?: string
  };
  content: Node;
  children: Node[];
}

interface SpanElement extends Parent {
  type: 'element';
  tagName: 'span';
  properties: Record<string, unknown>;
  content: Node;
  children: Node[];
}

type Element = AnchorElement | SpanElement;

function isAnchorElement(node: unknown): node is AnchorElement {
  return typeof node === 'object' &&
    !! node &&
    'type' in node &&
    node.type === 'element' &&
    'tagName' in node &&
    node.tagName === 'a';
}

function convertAnchorToSpan(node: AnchorElement) {
  const spanNode = (node as unknown as SpanElement);

  if (spanNode.properties === undefined) {
    node.properties = {};
  }

  spanNode.tagName = 'span';

  // Remove href/target properties
  delete spanNode.properties['href'];
  delete spanNode.properties['target'];
}

export function rehypeRemoveRelativeAnchors<Tree extends Node = Node>() {
  return function (tree: Tree) {
    return visit(tree, isAnchorElement, (node: Element) => {
      if (isAnchorElement(node) && !isAbsoluteUrl(node.properties.href ?? '')) {
        convertAnchorToSpan(node);
      }
    });
  }
}
