import React, { createContext } from 'react';
import { SourceView } from '@/types';
import { createUseContext } from '../../../../utils/create-use-context';

const SourcesContext = createContext<SourceView[] | undefined>(undefined);
SourcesContext.displayName = 'SourcesContext';

export const useSourcesContext = createUseContext(SourcesContext);

export const SourcesProvider = ({ sources, children }: React.PropsWithChildren<{ sources: SourceView[]}>) => (
  <SourcesContext.Provider value={sources}>{children}</SourcesContext.Provider>
);
