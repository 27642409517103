import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import React from 'react';
import isAbsoluteUrl from 'is-absolute-url';
import { backCompatUrl } from './external-badge';

type Props = {
  href?: string;
  location?: string;
  onClick: () => void;
}

export function ExternalLinkButton({ href, location, onClick }: Props) {
  if (!href) return null;
  // TODO: (backwards-compat). Future content likely won't have a location prop. Whether or not we show a 'link' button
  //   should probably be determined by if a url is absolute or not?
  const show = location === 'external' || isAbsoluteUrl(href);

  if (!show) return null;

  return (
    <Link
      title="Open source in new tab"
      type="button"
      target="_blank"
      className="rounded-md text-blue-400 focus:outline-none focus-visible:ring-2 inline-flex align-sub ml-2"
      to={backCompatUrl(href)}
      onClick={onClick}
      rel="noopener noreferrer"
    >
      <ArrowTopRightOnSquareIcon className="w-4 h-4" aria-hidden="true"/>
    </Link>
  )
}
