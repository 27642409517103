import { SourceData } from "@/types";
import React from "react";

export function SourceDescription({ data }: { data: SourceData }) {
  if (!data.description) {
    return null;
  }

  return (
    <div className="mt-4 mb-8">
      <h3 className="font-semibold mb-2">Description</h3>
      <div className="text-sm">{data.description}</div>
    </div>
  );
}
