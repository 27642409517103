import React, { PropsWithChildren } from 'react';

import BlueJ from '../../../../assets/svg/bluej.svg?react'

export function QuestionInitiated({ children }: PropsWithChildren) {
  return (
    <div className="flex-row gap-6 w-full max-w-5xl lg:flex">
      <div className="mb-4 flex justify-between items-center lg:block">
        <div className="bg-blue-600 rounded-full w-9 h-9 md:w-12 md:h-12 text-grey-600 flex justify-center items-center relative">
          <div className="absolute flex bg-white ring-blue-400 rounded-full shadow-[0_0_8px_0_#3A8FFA] origin-center transform-gpu w-9 h-9 md:w-12 md:h-12 animate-response-ping ring"></div>
          <div className="w-9 h-9 md:w-12 md:h-12 z-10 bg-blue-600 rounded-full">
            <BlueJ />
          </div>
        </div>
      </div>
      <div className="w-full mt-0">
        <div className="flex-col">
          { children }
        </div>
      </div>
    </div>
  )
}
