import { rehype } from 'rehype';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { rehypeRemoveRelativeAnchors } from './rehype-remove-relative-anchors';

export async function sanitizeHtml(content: string): Promise<string> {
  return rehype()
    .use(rehypeSanitize, {
      ...defaultSchema,
      attributes: {
        ...defaultSchema.attributes,
        p: [
          ...(defaultSchema.attributes?.p || []),
          ['className', 'breakline'],
        ],
        span: [
          ...(defaultSchema.attributes?.span || []),
          ['className', 'legacyTableRow']
        ]
      }
    })
    .use(rehypeRemoveRelativeAnchors)
    .use(rehypeExternalLinks, {
      target: '_blank',
      rel: 'noreferrer noopener nofollow'
    })
    .process(content)
    .then((result) => {
      return String(result);
    });
}

const HTML_REGEX = /(<([^>]+)>)/ig;

export function removeHtmlTags(content: string) {
  return content.replace(HTML_REGEX, '');
}
