import React from "react";
import { Placeholder } from "../../thread/components/placeholder";

export function Loading() {
  return (
    <div className="max-w-5xl mx-auto p-6 h-full animate-pulse mt-16">
      <div className="grid gap-2 grid-cols-1">
        <div className="w-60">
          <div className="rounded-sm h-4 bg-slate-200" />
        </div>
        <div className="w-80">
          <div className="rounded-sm h-4 bg-slate-200" />
        </div>
        <Placeholder count={3}>
          <div className="rounded-sm h-4 bg-slate-200" />
        </Placeholder>
        <div className="mt-12 grid gap-3 grid-cols-1">
          <Placeholder count={30}>
            <div className="rounded-sm h-4 bg-slate-200" />
          </Placeholder>
        </div>
      </div>
    </div>
  );
}
