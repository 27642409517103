import { useQuery } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';

export function useThreadListQuery() {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['thread-list'],
    queryFn: async () => {
      const { topics } = await api.getStrategies().catch(() => ({
        topics: []
      }));
      return api.getThreads().then((threads => {
        return threads
          .sort((a, b) => (a.updated_at ?? 0) < (b.updated_at ?? 0) ? 1 : -1)
          .map((thread) => ({
            title: thread.title,
            id: thread.id,
            topic: topics.find((topic) => topic.value === thread.topic)?.displayName || '',
            updated_at: thread.updated_at
          }));
      }))
    }
  });
}
