import React from 'react';

type Props = {
  withSources?: boolean;
};
export function UnableToAnswer({ withSources = false }: Props) {
  return (
    <div>
      {
        withSources ? (
          <p>While I can't provide a direct answer from the sources I found, I've gathered the sources below that could be helpful for further exploration of this topic. Open a source to ask questions about its content.</p>
        ) : (
          <p>Unfortunately, I am not able to provide a confident answer to your tax question in its current form. Please try again.</p>
        )
      }
      <div className="mt-4">
        <span>Here are some tips that may help:</span>
        <ul className="list-disc ml-6">
        <li>Break your question down into smaller parts.</li>
          <li>Rephrase your question.</li>
          <li>Check our <a href="https://www.bluej.com/customer-faq-ask" target="_blank" rel="noopener noreferrer"
                           className="text-blue-400 hover:text-blue-400">FAQ</a> for prompting suggestions.
          </li>
        </ul>
      </div>
    </div>
  )
}

