import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '../../../components/button';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { useAskBluejApiContext } from '../../core/api/ask-bluej-api';
import { useUser } from '../../core/user/context';

interface CommentFormValues {
  comment: string;
}

function isMoreThan90DaysAgo(lastSeen: number) {
  const date = new Date();
  const ninetyDaysAgo = date.setDate(date.getDate() - 90);

  return lastSeen < ninetyDaysAgo;
}

function useUserNPSStatusQuery() {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['npsStatus'],
    queryFn: async () => {
      return api.getUserNPSStatus();
    }
  });
}

export function NPSModal() {
  const [npsScore, setNpsScore] = React.useState<number | null>(null);
  const [modalVisible, setModalVisible] = React.useState(true);

  const { data } = useUserNPSStatusQuery();
  const { user } = useUser();
  const { trackUserNPSSurvey, trackNPSModalClosed } = useAnalyticsEvent();
  const { register, handleSubmit } = useForm<CommentFormValues>();

  const handleFormSubmit = (values: CommentFormValues) => {
    if (npsScore !== null) {
      trackUserNPSSurvey(npsScore, values.comment, user.email, `${user.firstName} ${user.lastName}`);
      setModalVisible(false);
      localStorage.setItem('npsModalShown', Date.now().toString());
    }
  }

  const handleModalDismiss = () => {
    setModalVisible(false);
    trackNPSModalClosed();
    localStorage.setItem('npsModalShown', Date.now().toString());
  }

  const expiry = localStorage.getItem('npsModalShown');

  const userQualifiesForNPS = !!data && data.qualifies;
  if (!userQualifiesForNPS || !modalVisible || (expiry !== null && !isMoreThan90DaysAgo(parseInt(expiry)))) {
    return null;
  }

  const zeroToTen = Array.from(new Array(11)).map((_, idx) => idx);
  return (
    <Dialog open={modalVisible} className="relative z-50" onClose={handleModalDismiss}>
      <div tabIndex={-1} className="backdrop-brightness-50 fixed z-50 inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center text-center p-4">
          <div className="relative overflow-hidden text-left sm:my-8 max-w-4xl bg-white rounded-lg shadow dark:bg-gray-700 p-6">
            <div>
              <Button
                colour="secondary"
                onClick={handleModalDismiss}
                title="Close modal"
                variant="icon"
                className='float-right -mt-4 -mr-4'
              >
                <span className="sr-only">Close modal</span>
                <XMarkIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true" />
              </Button>
              <div className="font-semibold mb-2">
                How likely are you to recommend Ask Blue J to a friend or colleague?
              </div>
              {
                zeroToTen.map(npsValue => (
                  <Button
                    key={npsValue}
                    className={clsx('mr-4 w-[48px] h-[48px] !rounded-full', {
                      'text-white': npsValue === npsScore
                    })}
                    colour={npsValue === npsScore ? "primary" : "secondary"}
                    onClick={() => {
                      if (npsValue !== npsScore) {
                        setNpsScore(npsValue);
                      }
                    }}
                    title={`${npsValue}`}
                    variant={npsValue === npsScore ? "contained" : "icon"}
                  >
                    {npsValue}
                  </Button>
                ))
              }
            </div>
            {
              npsScore !== null &&
              <div className="mt-6">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <label htmlFor="nps-comment" className="text-black-400 block mb-2">
                    {npsScore < 10 ? 'What changes would bring this experience closer to a 10 for you?' : 'What part of your experience was most important in making this a 10 for you?'}
                  </label>
                  <textarea id="nps-comment"
                            autoFocus={true} {...register('comment')}
                            className="block h-28 p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"/>
                  <Button
                    colour="primary"
                    title="Submit"
                    type="submit"
                    variant="contained"
                    className="py-1 px-3"
                  >
                    Submit
                  </Button>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}
