import { SourceView } from '@/types';
import { ErrorBoundary } from '@sentry/react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorFallback } from '../../../core/errors/error-fallback';
import { GlobalAnalyticsContextProvider } from '../../contexts/GlobalAnalyticsContext';
import { Panel } from '../panel';
import { RestrictedSourcesView } from './restricted-sources-view';
import { SourcesView } from './sources-view';

type Params = {
  threadId: string;
  chatId: string;
}

export function ActiveChatSourcePanelRoute() {
  const { threadId, chatId } = useParams<Params>();
  const { state } = useLocation();
  const navigate = useNavigate();

  const source: SourceView = state?.source;
  if (!source || !threadId || !chatId) {
    navigate(-1);
    return null;
  }
  const handleOnClose = () => {
    navigate(`/${threadId}`);
  }

  const answerStrategy = state?.answerStrategy || '';
  const isRestrictedSource = !('content' in source);

  return (
    <GlobalAnalyticsContextProvider value={{ threadId, chatId, answerStrategy }}>
      <Panel animate={true} onClose={handleOnClose}>
        <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
          {isRestrictedSource ?
            <RestrictedSourcesView sourceData={source} /> :
            <SourcesView sourceData={source} />
          }
        </ErrorBoundary>
      </Panel>
    </GlobalAnalyticsContextProvider>
  )
}
