import { SourceView } from '@/types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { getSourceTitle } from '../../../source/utils/get-source-title';
import { useGlobalAnalyticsContext } from '../../contexts/GlobalAnalyticsContext';
import { RestrictedSourceInteractionContext } from '../../types';
import { TitleComponent } from '../sources/source';
import { SourceBadge } from '../sources/source-badge';
import { ExternalBadge } from '../sources/external-badge';
import { ExternalLinkButton } from '../sources/external-link-button';

type AnimatedSourcesProps = {
  sources: SourceView[];
}

export const AnimatedSources = (props: AnimatedSourcesProps) => {
  const { sources } = props;
  const { chatId } = useGlobalAnalyticsContext();
  if (sources.length === 0) {
    return null;
  }

  return (
    <div id={`sources-list-${chatId}`}>
      <div className="mb-3">Sources</div>
      <div className="min-w-full text-grey-600 text-sm overflow-hidden">
        <div className="grid gap-y-2">
          {sources.map((source, index) => (
            <Source key={source.id} source={source} index={index}/>
          ))}
        </div>
      </div>
    </div>
  );
}

type SourceProps = {
  source: SourceView;
  index: number;
};

const Source: React.FC<SourceProps> = (props) => {
  const { source, index } = props;
  const { trackSourceLinkClicked, trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();
  const { threadId, chatId, answerStrategy } = useGlobalAnalyticsContext();
  const isRestrictedSource = !('content' in source);
  const handleSourceClick = useCallback(() => {
    trackSourceLinkClicked({
      sourceTitle: source.title,
      sourceType: source.displayType,
      contentType: source.contentType,
      answerStatus: 'in-progress',
      restricted: isRestrictedSource,
      referredUrl: source.href
    })
  }, [source.id]);

  const handleOpenInNewTabClick = useCallback(() => {
    if (!isRestrictedSource) {
      return;
    }
    trackRestrictedSourceOpenInNewTabClicked({
      sourceType: source.displayType,
      sourceTitle: source.title,
      interactionContext: RestrictedSourceInteractionContext.QUESTION_SOURCE_LIST,
      referredUrl: source.href
    });
  }, [source]);

  return (
    <>
      <div className="grid grid-cols-[5.75rem_auto]">
        <div>
          <SourceBadge displayType={source.displayType} />
        </div>
        <div>
          <Link
            className="text-blue-400 hover:underline underline-offset-4 cursor-pointer"
            to={`/${threadId}/chat/${chatId}/active/source`}
            state={{ source, answerStrategy }}
            onClick={handleSourceClick}
          >
            <TitleComponent title={`[${index + 1}] ${getSourceTitle(source)}`}/>
          </Link>
          <ExternalBadge
            badge={source.externalBadge}
            href={source.externalUrl || source.href}
            restricted={isRestrictedSource}
            onClick={handleOpenInNewTabClick}
          />
          <ExternalLinkButton
            href={source.externalUrl || source.href}
            onClick={handleOpenInNewTabClick}
          />
        </div>
      </div>
    </>
  );
};
