import React from 'react';
import { SourceMetaRow } from './source-meta-row';
import { getAuthorLabelForSource } from '../utils/get-published-label-for-source';
import { CaseMeta, DocumentMeta } from '@/types';

type SourceHeaderProps = {
  data?: DocumentMeta | CaseMeta;
  type?: string;
}

export const SourceMeta: React.FC<SourceHeaderProps> = ({ data, type }) => {
  if (!data) return null;

  if (type === 'document') {
    const documentMeta = data as DocumentMeta;
    const author = getAuthorLabelForSource(documentMeta.author?.join(' ') ?? '', documentMeta.writer);

    return (
      <div className="text-black-400 p-3 rounded-2xl flex flex-row gap-6 my-2 bg-grey-200 meta-box overflow-hidden print:bg-white print:border">
        <table className="w-full text-sm">
          <tbody>
            { author && <SourceMetaRow title="Author" value={author} /> }
            <SourceMetaRow title="Citation/Reference" value={documentMeta.citations.join(', ')} />
            <SourceMetaRow title="Type" value={documentMeta.documentType ?? ''} />
            <SourceMetaRow title="Released" value={documentMeta.dateReleased} />
            <SourceMetaRow title="Published" value={documentMeta.datePublished} />
            <SourceMetaRow title="Jurisdiction" value={data.jurisdiction} />
          </tbody>
        </table>
      </div>
    )
  }

  if (type === 'case') {
    const caseMetadata = data as CaseMeta;
    return (
      <div className="text-black-400 p-3 rounded-2xl flex flex-row gap-6 my-2 bg-grey-200 meta-box overflow-hidden print:bg-white print:border">
        <table className="w-full text-sm">
          <tbody>
            <SourceMetaRow title="Citation/Reference" value={caseMetadata.citations} />
            <SourceMetaRow title="Judgment date" value={caseMetadata.judgementDate} />
            <SourceMetaRow title="Docket number(s)" value={caseMetadata.fileNumber.join(', ')} />
            <SourceMetaRow title="Jurisdiction" value={caseMetadata.jurisdiction} />
            <SourceMetaRow title="Court" value={caseMetadata.court} />
          </tbody>
        </table>
      </div>
    )
  }
}
