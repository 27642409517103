import { SourceData } from '@/types';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

import { Button } from '../../../components/button';
import { isNoAnswer } from '../../ask-bluej';
import { AskDocStreamState } from '../../ask-bluej/streaming/doc/reducer';
import { AskDocInteractionType } from '../../ask-bluej/streaming/doc/shared';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { CopyToClipboard } from '../../thread/components/copy-to-clipboard';

type AnswerPanelProps = {
  question: string;
  onClose: () => void;
  interactionContext: AskDocInteractionType;
  state: AskDocStreamState;
  sourceData: SourceData;
  animate: boolean;
  additionalSources?: boolean;
}

export function AnswerPanel({ children, question, onClose, interactionContext, state, sourceData, animate, additionalSources }: PropsWithChildren<AnswerPanelProps>) {
  const { trackDocQuestionCopied } = useAnalyticsEvent();

  const panelPosition = clsx('fixed inset-y-0 inset-x-0 lg:right-0 lg:left flex items-end justify-center', {
    'left-0': interactionContext === AskDocInteractionType.PAGE
  });

  const panelStyle = clsx('flex relative w-full min-h-[25%] max-h-dynamic-full h-fit bg-white', {
    'ml-auto max-w-[1000px] lg:w-[50vw]': interactionContext !== AskDocInteractionType.PAGE,
    'mx-auto rounded-t-lg shadow-lg max-w-5xl': interactionContext === AskDocInteractionType.PAGE
  });

  const showCopyToClipboard = state.state === 'complete' && !isNoAnswer(state.answer);
  return (
    <Transition show={true} appear={animate}>
      <Dialog onClose={onClose} className="relative z-50">
        <TransitionChild
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className={panelPosition}>
          <TransitionChild
            enter="transform transition ease-in-out duration-300"
            enterFrom="transform translate-y-full"
            enterTo="transform translate-y-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="transform translate-y-0"
            leaveTo="transform translate-y-full"
          >
            <DialogPanel
              className={panelStyle}>
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-end pt-4 px-4 gap-2">
                  {showCopyToClipboard && (
                    <div className="flex">
                      <CopyToClipboard
                        content={state.answer}
                        trackCopyToClipboard={() => trackDocQuestionCopied({
                          interactionContext,
                          questionCharacterCount: question.length,
                          responseCharacterCount: state.answer.length,
                          sourceType: sourceData.type,
                          sourceTitle: sourceData.title,
                          sourceId: sourceData.id,
                          additionalSources
                        })}
                      />
                    </div>
                  )}
                  <Button
                    colour="secondary"
                    onClick={onClose}
                    title="Close panel"
                    variant="icon"
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true" />
                  </Button>
                </div>
                <DialogTitle className="font-semibold px-4 py-5">
                  {question}
                </DialogTitle>
                <hr className="h-px mb-5 bg-gray-400" />
                <ScrollToBottom
                  className="overflow-y-auto"
                  followButtonClassName="hidden"
                  initialScrollBehavior="auto"
                >
                  <div className="px-4 pb-6">
                    {children}
                  </div>
                </ScrollToBottom>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}
