import { SourceView } from '@/types';

type sourceProps = {
  displayType: SourceView['displayType'];
  contentType?: SourceView['contentType'];
  title: string;
}
export function getSourceTitle(props: sourceProps) {
  const { displayType, contentType, title } = props;
  return contentType && displayType === 'commentary' ? `${contentType}: ${title}` : title;
}
