import React, { useEffect } from 'react';
import { User } from '@/types';

import { useUser } from '../user/context';
import { useConfigContext } from "../../config/ConfigContext";

import { AnalyticsEvent, AnalyticsProviderValue, CustomerIoAnalytics } from './types';
import { createUseContext } from '../../../utils/create-use-context';

import { SegmentAnalytics } from './segment';
import { EventProperties, page, track } from './analytics';
import { useSegmentAnalytics } from './useSegmentAnalytics';
import { useCustomerioAnalytics } from './useCustomerioAnalytics';

const AnalyticsContext = React.createContext<AnalyticsProviderValue | null>(null);
AnalyticsContext.displayName = 'AnalyticsContext';

export function AnalyticsProvider({ children }: React.PropsWithChildren) {
  const { environment } = useConfigContext();
  const { user } = useUser();
  const { userAgent } = navigator;
  const { innerWidth, innerHeight } = window;

  const { segmentAnalytics } = useSegmentAnalytics();
  const { customerioAnalytics } = useCustomerioAnalytics();

  const baseEventProperties: EventProperties = {
    environment,
    userAgent: userAgent,
    screenResolution: `${innerWidth} x ${innerHeight}`,
    screenWidth: innerWidth,
    screenHeight: innerHeight
  };

  useEffect(() => {
    void identifyUser(user, environment, segmentAnalytics, customerioAnalytics);
  }, [user, environment]);

  const value: AnalyticsProviderValue = {
    track: (event: AnalyticsEvent) => track(segmentAnalytics, event.action, { ...event.properties, ...baseEventProperties }),
    page: (category: string, name?: string) => page(segmentAnalytics, category, name, baseEventProperties),
  };

  return (
    <AnalyticsContext.Provider value={value}>
      { children }
    </AnalyticsContext.Provider>
  );
}

async function identifyUser(user: User, environment: string, segmentAnalytics: SegmentAnalytics, customerIoAnalytics: CustomerIoAnalytics) {
  if (segmentAnalytics) {
    // TODO: Do we _need_ to send identifying data from here or can we rely on the backend identify?
    //   - From here we only need to bind the session to the user id
    try {
      segmentAnalytics.identify(user.id, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`
      });
    } catch (e) {
      // no-op, don't let this break the app
    }
  }

  if (customerIoAnalytics) {
    try {
      // TODO: Same question as above, can/should we only send id/sub?
      customerIoAnalytics.identify({
        id: user.id,
        email: user.email,
        // This is helpful for us to differentiate between internal users due to having the same identifying data
        //   and different ids across environments
        environment,
        subscription: 'askbluej',
      });
    } catch (e) {
      // no-op, don't let this break the app
    }
  }
}

export const useAnalytics = createUseContext(AnalyticsContext, {
  // No-ops
  track: () => Promise.resolve(),
  page: () => Promise.resolve()
});
