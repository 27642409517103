import { AvailableSourceView, SourceData } from '@/types';
import React, { useEffect, useState } from 'react';
import { useSourceContentQuery } from '../../useSourceContentQuery';
import { renderToString } from 'react-dom/server';
import { SourceDescription } from './source-description';
import { usePrintPage } from '../../hooks/usePrintPage';

export function usePrintSourceData(sourceData: AvailableSourceView) {
  const [printData, setPrintData] = useState<SourceData | null>(null);
  const { data: sourceContentData, status: sourceContentStatus } = useSourceContentQuery(sourceData);

  useEffect(() => {
    sourceContentStatus === 'success' && setPrintData(sourceContentData);
  }, [sourceContentStatus, sourceContentData]);

  const description = printData ? renderToString(<SourceDescription data={printData}/>) : '';
  const sourceContent = description ? `<div>${description}${printData?.content}</div>` : sourceData.content;
  const { printComponentRef, handlePrint } = usePrintPage(printData?.title || sourceData.title);

  return { printData, sourceContent, printComponentRef, handlePrint, sourceContentData, sourceContentStatus };
}
