import { useQuery } from '@tanstack/react-query';

import { sortSourceByDisplayType } from '../source/utils/sort-source-by-display-type';
import { useSourceApiContext } from '../thread/contexts/SourceApiContext';

export function useAdditionalSourcesQuery(threadId: string, chatId: string) {
  const { api } = useSourceApiContext();

  return useQuery({
    queryKey: ['additional-sources', threadId, chatId],
    queryFn: async () => {
      const sources = await api.getAdditionalSources(threadId, chatId);
      return sources.sort(sortSourceByDisplayType);
    },
    staleTime: Infinity,
    gcTime: Infinity
  });
}
