import React from 'react';
import { QueryStatus } from '@tanstack/react-query';
import { Preferences, User, UserPreferences } from '@/types';

import { createUseContext } from '../../../utils/create-use-context';
import { useUpdateUserPreferencesQuery } from '../user-preferences/useUpdateUserPreferencesQuery';
import { useUserPreferencesQuery } from '../user-preferences/useUserPreferencesQuery';

type UserContextValue = {
  user: User;
  updateUserPreferences:  (data: Preferences) => Promise<UserPreferences>
  preferences?: Preferences;
  preferencesDataStatus: QueryStatus;
}

const UserContext = React.createContext<UserContextValue | null>(null);
UserContext.displayName = 'UserContext';

type Props = {
  user: User;
}

export function UserProvider({ children, user }: React.PropsWithChildren<Props>) {
  const { updateUserPreferences } = useUpdateUserPreferencesQuery();
  const { data: preferences, status: preferencesDataStatus } = useUserPreferencesQuery();

  const value = {
    user,
    updateUserPreferences,
    preferences,
    preferencesDataStatus
  }

  return (
    <UserContext.Provider value={value}>
      { children }
    </UserContext.Provider>
  )
}
export const useUser = createUseContext(UserContext);

export function useIsUserAdmin() {
  const { user } = useUser();
  return user.isAdmin;
}
