import { BookcaseMeta, BookcaseSourceData, CaseMeta, DocumentMeta, SourceView } from '@/types';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import EventEmitter from 'eventemitter3';
import React, { useEffect } from 'react';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { getSourceTitle } from '../../../source/utils/get-source-title';
import { useSourceContentQuery } from '../../useSourceContentQuery';
import { SourceBadge } from './source-badge';
import { SourceDetailsTable } from './details-table';
import { SourceMeta } from './source-meta/source-meta';

type SourceHeaderProps = {
  sourceData: SourceView;
  additionalSources: boolean;
  emitter?: EventEmitter;
}

type ContentMetadataProps = {
  metadata?: DocumentMeta | CaseMeta | BookcaseMeta;
};

export function ContentMetaData({ metadata }: ContentMetadataProps) {
  if (!metadata || !metadata.breadcrumbs) {
    return null;
  }

  const breadcrumbComponent = Array.isArray(metadata.breadcrumbs) ?
    metadata.breadcrumbs.map((breadcrumb, index) => (
      <div key={index} className="inline text-gray-900">{breadcrumb.value} { index !== metadata.breadcrumbs.length - 1 && ' > '}</div>
    )) :
    <div className="inline text-gray-900">{ metadata.breadcrumbs }</div>
  return (
    <div className="text-xs font-semibold inline">
      { breadcrumbComponent }
    </div>
  )
}

type SourceDetailsDisclosureProps = {
  onClick: () => void;
  showMeta: boolean;
  detailsTableData?: SourceView['detailsTableData'];
  backCompatMetadata?: DocumentMeta | CaseMeta | BookcaseMeta; // FIXME Temporary while we're getting curator -> CAM thru
  type?: string;
}

function SourceDetailsDisclosure(props: SourceDetailsDisclosureProps) {
  const { onClick, showMeta, detailsTableData, backCompatMetadata, type } = props;

  // FIXME (backwards-compat)
  if (type === 'statute' || type === 'regulation') return null;
  // FIXME (backwards-compat)
  if (type === 'webpage_irsfaq' || type === 'vlex_hmrc' || type === 'vlex_legislation') return null;
  // FIXME (backwards-compat)
  if ((!detailsTableData || Object.keys(detailsTableData).length === 0) && (!backCompatMetadata || Object.keys(backCompatMetadata).length === 0)) return null;

  return (
    <Disclosure defaultOpen>
      <DisclosureButton className="text-sm text-blue-400 hover:underline underline-offset-4" onClick={onClick}>
        { showMeta ? 'Hide detail' : 'Show detail' }
      </DisclosureButton>
      <Transition
        as="div"
        show={showMeta}
        className="overflow-hidden"
        enter="transition transition-[max-height] duration-300 ease-in"
        enterFrom="transform max-h-0"
        enterTo="transform max-h-screen"
        leave="transition transition-[max-height] duration-300 ease-out"
        leaveFrom="transform max-h-screen"
        leaveTo="transform max-h-0"
      >
        <DisclosurePanel static>
          <SourceMeta data={backCompatMetadata as DocumentMeta} type={type} />
          <SourceDetailsTable data={detailsTableData} />
        </DisclosurePanel>
      </Transition>
    </Disclosure>
  );
}

type MetadataProps = {
  metadata?: CaseMeta | DocumentMeta | BookcaseSourceData['metadata'];
  onClick: () => void;
  showMeta: boolean;
  detailsTableData?: Record<string, unknown>;
  type?: string;
}

function Metadata({ metadata, showMeta, onClick, detailsTableData, type }: MetadataProps) {
  return (
    <>
      <ContentMetaData metadata={metadata} />
      <SourceDetailsDisclosure detailsTableData={detailsTableData} showMeta={showMeta} onClick={onClick} backCompatMetadata={metadata} type={type} />
    </>
  );
}

export const SourceHeader: React.FC<SourceHeaderProps> = ({ sourceData, emitter, additionalSources }) => {
  const sourceContent = useSourceContentQuery(sourceData);
  const sourceContentData = sourceContent.data;
  const [showMeta, setShowMeta] = React.useState(true);
  const [isShowMetaFixed, setIsShowMetaFixed] = React.useState(false);
  const { trackSourceDetailsToggledOn, trackSourceDetailsToggledOff } = useAnalyticsEvent();

  const handleShowHideMeta = () => {
    if (!showMeta) {
      trackSourceDetailsToggledOn({ sourceType: sourceData.type, additionalSources });
    } else {
      trackSourceDetailsToggledOff({ sourceType: sourceData.type, additionalSources });
    }
    setIsShowMetaFixed(true);
    setShowMeta(!showMeta);
  }

  React.useEffect(() => {
    function handleEmit() {
      if (isShowMetaFixed) {
        return;
      }
      setShowMeta(false);
    }
    emitter?.addListener('scroll', handleEmit);
    return () => {
      emitter?.removeListener('scroll', handleEmit);
    };
  }, [emitter, isShowMetaFixed]);

  useEffect(() => {
    setIsShowMetaFixed(false);
    setShowMeta(true);
  }, [sourceData]);

  return (
    <div className="px-6 pb-2 border-b">
      <SourceBadge displayType={sourceData.displayType} />
      <h2 className="font-semibold my-2">{getSourceTitle(sourceData)}</h2>

      <Metadata
        metadata={sourceContentData?.metadata}
        onClick={handleShowHideMeta}
        showMeta={showMeta}
        detailsTableData={sourceContentData?.detailsTableData}
        type={sourceContentData?.type}
      />
    </div>
  )
}
