import { SourceView } from '@/types';
import React from 'react';

type SourceBadgeProps = {
  displayType: SourceView['displayType'];
}

export const SourceBadge: React.FC<SourceBadgeProps> = ({ displayType }) => {
  return (
    <div className="print-bg inline-block font-medium text-xxs px-2 py-1 rounded-xl text-black-600 leading-3 bg-blue-200">
      {(displayType ?? '').toUpperCase()}
    </div>
  )
}
