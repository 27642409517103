import { SourceData } from "@/types";
import React from "react";

export function SourceTitle({ data }: { data: SourceData }) {
  if (!data.description) {
    return null;
  }

  return (
    <h4 className="font-semibold my-2 text-md mt-2">{data.title}</h4>
  );
}
