import { SourceContentQueryProps } from '../../thread/useSourceContentQuery';

const SourceTitlePrefixLookup: Record<string, string> = {
  'C.R.C., c. 945': 'ITR s. ',
  'I-3.3': 'ITA s. ',
  'E-15': 'ETA s. ',
  '1992.c.12': 'TCGA 1992, s ',
  '2009.c.4': 'Corporation Tax Act 2009, s ',
  '2010.c.8': 'TIOPA 2010, s ',
  '2010.c.4': 'Corporation Tax Act 2010, s '
};

export function getSourceTypeAndParams(ref: string): SourceContentQueryProps | undefined {
  const [type, ...params] = ref.split('/');

  // FIXME backwards-compat
  if (type === 'case' || type === 'document' || type === 'curator_case' || type === 'curator_document') {
    return {
      type,
      id: params[0]
    }
  }

  if (type === 'statute' || type === 'regulation') {
    const title = params[0];
    const sectionNumber = params[1].split('?')[0];
    return {
      type,
      id: `/${title}/${type}/${sectionNumber}`,
      // This is used to maintain readability of a statute/regulation when loading into a source view that has no context
      // of a question (i.e. ref?title/type/statute, etc.)
      titlePrefix: SourceTitlePrefixLookup[title]
    }
  }
}
