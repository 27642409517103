import React, { PropsWithChildren } from 'react';
import { UserIcon } from '@heroicons/react/24/solid';

type Props = {
  questionComponent: React.ReactNode | null;
};

export function QuestionViewContainer({ children, questionComponent }: PropsWithChildren<Props>) {
  return (
    <>
      <div className="border text-black-400 p-3 rounded-2xl flex flex-row md:gap-4 lg:gap-6 group border-grey-200 bg-grey-200 mb-4">
        <div className="hidden md:flex">
          <div className="bg-green-200 rounded-full w-9 h-9 md:w-12 md:h-12 text-black-600 flex justify-center items-center">
            <UserIcon className="w-6 h-6 block" />
          </div>
        </div>
        <div className="flex flex-row justify-between w-full items-center md:gap-4 lg:gap-6">
          { questionComponent }
        </div>
      </div>
      <div className="border text-black-400 p-3 rounded-2xl flex flex-row lg:gap-6 border-grey-400">
        { children }
      </div>
    </>
  )
}
