import { getSourceTypeAndParams } from './utils/get-source-type-and-params';
import { useLocation, useSearchParams } from 'react-router-dom';

// This is pretty specific to route-handling and backwards compatibility for links from various places
export function getSourceIdAndType(pathname: string, searchParams: URLSearchParams): { id: string | null, type: string } {
  const ref = searchParams.get('ref');

  if (ref) {
    const deprecatedSourceContentQueryProps = getSourceTypeAndParams(ref);
    if (!deprecatedSourceContentQueryProps) {
      return {
        id: null,
        type: ''
      }
    }
    return deprecatedSourceContentQueryProps;
  }

  const splitPath = pathname.split('/')
    .filter((chunk) => {
      if (!chunk) return false;
      return chunk !== 'source';
    });

  // Maintaining backwards-compat for urls which contain types, e.g. "source/document/z3c3f3"
  if (splitPath.length > 1) {
    return {
      type: splitPath[0],
      id: splitPath[1]
    }
  }

  // new-style urls "/source/5fe09334-c61a-504e-886b-e004cb75667e"
  return {
    type: 'cam',
    id: splitPath[0]
  }
}

export function useGetSourceIdAndType() {
  const { pathname } = useLocation();
  const [ searchParams ] = useSearchParams();

  return getSourceIdAndType(pathname, searchParams);
}
