import { SourceData } from "@/types";
import React, { useCallback } from "react";
import TaxNotes from '../../../assets/svg/taxnotes.svg?react';
import { useAnalyticsEvent } from "../../core/analytics/useAnalyticsEvent";
import { RestrictedSourceInteractionContext } from "../../thread/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export function RestrictedSourceContent({ data }: { data: SourceData }) {
  const { trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();
  const isRestricted = (data.type === 'document' || data.type === 'case') && data.isRestricted;

  if (!isRestricted) {
    return null;
  }

  const handleViewInTaxNotesClicked = useCallback(() => {
    trackRestrictedSourceOpenInNewTabClicked({
      sourceType: data.displayType,
      sourceTitle: data.title,
      referredUrl: data.href,
      interactionContext: RestrictedSourceInteractionContext.VIEW_IN_TAX_NOTES
    });
  }, [data.id]);

  return (
    <div className="text-sm">
      This source is available to read in Tax Notes. The full text of the source was read in order to generate your answer. Click below to access this source.
      <div className="pt-6">
        <a className="text-blue-400 hover:underline underline-offset-4" href={data.href} target="_blank"
           rel="noreferrer noopener" onClick={handleViewInTaxNotesClicked}>
          <div className="flex gap-1 items-center">
            <span title="View in Taxnotes">View in</span><TaxNotes className="h-5" aria-hidden="true" />
            <ArrowTopRightOnSquareIcon className="w-4 h-4" aria-hidden="true" />
          </div>
        </a>
      </div>
    </div>
  );
}
