import React from "react";
import { SourceView } from "@/types";
import { SourceBadge } from "../../thread/components/sources/source-badge";

type SourceHeaderProps = {
  displayType: SourceView['displayType'];
  title: string;
}
export function SourceHeader({ displayType, title }: SourceHeaderProps) {
  return (
    <div className="w-full">
      <SourceBadge displayType={displayType} />
      <h2 className="font-semibold my-2">{title}</h2>
    </div>
  );
}
