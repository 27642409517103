import { SourceView } from '@/types';
import React, { PropsWithChildren, useMemo } from 'react';

import BlueJ from '../../../../assets/svg/bluej.svg?react'
import { isNoAnswer } from '../../../ask-bluej';
import { AnswerMarkdown } from '../answer-markdown';
import { InlineCitationLink } from '../inline-citations/inline-citation-link';
import { replaceInlineCitationsWithLinks } from '../inline-citations/replace-inline-citations';
import { SourcesProvider } from '../sources/sources-provider';
import { UnableToAnswer } from '../unable-to-answer';

export function Answering({ isComplete, isAborted, answer, children, sources = [] }: PropsWithChildren<AnsweringProps>) {
  return (
    <div className="flex-row gap-6 w-full max-w-5xl lg:flex">
      <div className="mb-4 flex justify-between items-center lg:block">
        <div className="bg-blue-600 rounded-full w-9 h-9 md:w-12 md:h-12 text-grey-600 flex justify-center items-center relative">
          <div className="absolute flex bg-white rounded-full origin-center w-9 h-9 md:w-12 md:h-12"></div>
          <div className="w-9 h-9 md:w-12 md:h-12 z-10 bg-blue-600 rounded-full">
            <BlueJ />
          </div>
        </div>
      </div>
      <div className="self-center">
        <AnswerViewSwitch answer={answer} isComplete={isComplete} isAborted={isAborted} sources={sources} />
        { children }
      </div>
    </div>
  );
}

type AnsweringProps = {
  answer: string;
  isComplete: boolean;
  isAborted: boolean;
  sources?: SourceView[];
}

function AnswerViewSwitch(props: AnswerSwitchProps) {
  const { answer, isComplete, isAborted, sources } = props;

  if (isComplete && !isAborted && isNoAnswer(answer)) {
    return <UnableToAnswer withSources={sources.length > 0} />;
  }

  const InlineCitationRender = useMemo(() => InlineCitationLink(true), []);
  return (
    <SourcesProvider sources={sources}>
      <AnswerMarkdown answer={replaceInlineCitationsWithLinks(answer, sources)} renderInlineCitation={InlineCitationRender} />
    </SourcesProvider>
  );
}

type AnswerSwitchProps = {
  answer: string;
  isComplete: boolean;
  isAborted: boolean;
  sources: SourceView[];
}
