import { SourceData } from '@/types';
import React from 'react';

export function SourceDescription({ data }: { data: SourceData | null }) {
  if (!data) return null;
  if (!data.description) return null;

  return (
    <div className="mb-8">
      <h4 className="font-semibold mt-0">Description</h4>
      <div className="text-sm">{data.description}</div>
      <h4 className="font-semibold my-2 text-md mt-2">{data.title}</h4>
    </div>
  );
}
