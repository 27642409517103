import { SourceData, SourceView } from '@/types';
import React, { forwardRef } from 'react';
import Logo from '../../../../assets/svg/logo.svg?react'
import { SourceHeader as SourceHeaderPrint } from '../../../source/components/source-header';
import { replaceLinks } from '../../../source/utils/replace-links';
import { SourceHighlighter } from '../../source-highlighter/source-highlighter';
import { SourceBadge } from './source-badge';
import { SourcePublished } from "../../../source/components/source-published";
import { ContentMetaData } from './source-header';
import { SourceDetailsTable } from './details-table';

type PrintSourceProps = {
  printData?: SourceData | null;
  source: string;
  content: string;
  sourceData: SourceView;
}
export const PrintSource = forwardRef<HTMLDivElement, PrintSourceProps>(
  ({ printData, source, content, sourceData }: PrintSourceProps, ref) => {
    return (
      <div className="hidden">
        <div ref={ref}>
          <div className="max-w-5xl mx-auto ">
            <div className="flex flex-col items-center">
              <Logo className="w-48 h-auto"/>
              <div className="items-start print-bg">
                {printData && (
                  <>
                    <SourceHeaderPrint displayType={printData.displayType} title={printData.title}/>
                    <SourcePublished data={printData}/>
                    <ContentMetaData metadata={printData.metadata} />
                    <SourceDetailsTable data={printData.detailsTableData} />
                  </>
                )}
                {sourceData?.externalUrl && (
                  <>
                    <SourceBadge displayType={sourceData.displayType}/>
                    <div className="my-2">
                      <a className="text-blue-400 hover:underline underline-offset-4" href={sourceData.href}
                         target="_blank"
                         rel="noreferrer noopener">
                    <span className="whitespace-nowrap overflow-hidden text-ellipsis" title={sourceData.title}>
                      {sourceData.title}
                    </span>
                      </a>
                    </div>
                  </>
                )}
                <div className="mt-2 prose prose-pre:whitespace-pre-wrap [&_*]:!prose-sm max-w-full prose-custom-links">
                  <SourceHighlighter
                    content={content}
                    source={source}
                    onContentReady={replaceLinks}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
)

