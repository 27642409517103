import { PartialThread } from '@/types';
import React from 'react';
import { ThreadListComponent } from './thread-list-component';

interface Props {
  activeThreadId?: string;
  threads: PartialThread[];
}

export const GroupedThreadList = (props: Props) => {
  const { threads, activeThreadId } = props;

  const now = new Date();
  const prev7Days: PartialThread[] = [];
  const moreThanWeek: PartialThread[] = [];
  const moreThanMonth: PartialThread[] = [];

  threads.forEach(({ id, title, topic, updated_at }) => {
    const updatedAtDate = new Date(updated_at || 0);
    const diffInDays = Math.floor((now.getTime() - updatedAtDate.getTime()) / (1000 * 60 * 60 * 24));
    if (diffInDays <= 7) {
      prev7Days.push({ id, title, topic });
    } else if (diffInDays <= 30) {
      moreThanWeek.push({ id, title, topic });
    } else {
      moreThanMonth.push({ id, title, topic });
    }
  });

  return (
    <>
      <ThreadListComponent threads={prev7Days} activeThreadId={activeThreadId} />
      <ThreadListComponent threads={moreThanWeek} activeThreadId={activeThreadId} header="Older than a week" />
      <ThreadListComponent threads={moreThanMonth} activeThreadId={activeThreadId} header="Older than a month" defaultOpen={false} />
    </>
  );
}
