import { Transition } from '@headlessui/react';
import { PrinterIcon } from '@heroicons/react/24/solid';
import { ErrorBoundary } from '@sentry/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/svg/logo.svg?react';
import { Button } from '../../../components/button';
import { AskDocInteractionType } from '../../ask-bluej/streaming/doc/shared';
import { AskDocument } from '../../ask-doc/components/ask-doc';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { PageTitle } from '../../core/app/page-title';
import { ErrorFallback } from '../../core/errors/error-fallback';
import { SourceDetailsTable } from '../../thread/components/sources/details-table';
import { ContentMetaData } from '../../thread/components/sources/source-header';
import { usePrintPage } from '../../thread/hooks/usePrintPage';
import { useSourceContentQuery } from '../../thread/useSourceContentQuery';
import { Loading } from '../components/loading';
import { NotFound } from '../components/not-found';
import { RestrictedSourceContent } from '../components/restricted-source-content';
import { SourceContent } from '../components/source-content';
import { SourceDescription } from '../components/source-description';
import { SourceHeader } from '../components/source-header';
import { SourcePublished } from '../components/source-published';
import { SourceTitle } from '../components/source-title';
import { useGetSourceIdAndType } from '../get-source-id-and-type';
import { useScrollToHash } from '../hooks/useScrollToHash';
import { replaceLinks } from '../utils/replace-links';

export function SourceRoute() {
  const { id, type } = useGetSourceIdAndType();

  if (!id) {
    return <NotFound />
  }

  const { data, status } = useSourceContentQuery({ id, type });
  const { trackSourcePrintClicked } = useAnalyticsEvent();
  const { printComponentRef, handlePrint } = usePrintPage(data?.title || '');

  const initialState = localStorage.getItem(`ask-${AskDocInteractionType.PAGE}-tooltip`) !== 'true';
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  useScrollToHash([status, data?.content]);

  if (status === 'pending') {
    return <Loading />
  }

  if (status === 'error' || data === undefined || data === null) {
    return <NotFound />
  }

  return (
    <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
      <PageTitle title={data.title} />
      <div className="flex flex-col h-dynamic-full justify-between overflow-hidden">
        <div className="w-full h-full overflow-y-auto">
          <div ref={printComponentRef} className="max-w-5xl mx-auto p-6">
            <div className="flex flex-col items-center">
              <div className="flex justify-between w-full items-center">
                <div className="flex-1 flex  justify-center">
                  <Link to="/">
                    <Logo className="w-48 h-auto" />
                  </Link>
                </div>
                <Button
                  className="hidden md:inline-flex print:hidden px-3 py-1 items-center gap-x-1.5 text-sm"
                  colour="primary"
                  onClick={() => {
                    trackSourcePrintClicked({ sourceType: data.type, sourceTitle: data.title })
                    setTimeout(() => handlePrint(), 1);
                  }}
                  title="Print page"
                  variant="contained"
                >
                  <PrinterIcon className="h-6 w-6 accent-white" />
                  Print
                </Button>
              </div>
              <div className="items-start">
                <SourceHeader displayType={data.displayType} title={data.title} />
                <SourcePublished data={data} />
                <ContentMetaData metadata={data.metadata} />
                <SourceDetailsTable data={data.detailsTableData} />
                <SourceDescription data={data} />
                <SourceTitle data={data} />
                <SourceContent content={data.content} onContentReady={replaceLinks} />
                <RestrictedSourceContent data={data} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-transparent h-[74px]" />
      </div>
      <Transition
        appear={true}
        show={true}
        enter="transition-all transform ease-in duration-700"
        enterFrom="translate translate-y-full"
        enterTo="translate translate-y-0"
        afterEnter={() => setTooltipVisible(initialState)}
      >
        <div className="fixed bottom-0 mx-auto w-full">
          <AskDocument content={data.content} sourceData={data} interactionContext={AskDocInteractionType.PAGE} docQuestionTooltip={{ isTooltipVisible, setTooltipVisible }}/>
        </div>
      </Transition>
    </ErrorBoundary>
  );
}


