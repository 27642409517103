import { RestrictedSourceView, SourceData } from '@/types';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { QueryStatus } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { RestrictedSourceInteractionContext } from '../../types';
import { useSourceContentQuery } from '../../useSourceContentQuery';
import { ExternalSourceLink } from '../external-source-link';
import { SourceHeader } from './source-header';
import { SourceViewErrorComponent, SourceViewLoadingComponent } from './sources-view';

interface RestrictedSourceViewProps {
  successComponent?: React.ElementType;
  sourceData: RestrictedSourceView;
  sourceNavigationComponent?: React.ReactElement;
  additionalSources?: boolean;
}

export function RestrictedSourcesView(props: RestrictedSourceViewProps) {
  const {
    successComponent: SuccessComponent = SourcesViewSuccessComponent,
    sourceData,
    sourceNavigationComponent,
    additionalSources = false
  } = props;
  const { trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();
  const { href, title } = sourceData;
  const { data: sourceContentData, status: sourceContentStatus } = useSourceContentQuery(sourceData);


  const headerClasses = clsx('flex mb-2', {
    'justify-between': sourceNavigationComponent,
    'justify-end': !sourceNavigationComponent,
  })
  return (
    <>
      <div className="flex flex-col overflow-hidden w-full">
        <div className="px-6 pt-6">
          <div className={headerClasses}>
            {sourceNavigationComponent}
            {href && (
              <SourcePanelNavigationButtons
                url={href}
                handleOpenSource={() => {
                  trackRestrictedSourceOpenInNewTabClicked({
                    sourceType: sourceData.displayType,
                    sourceTitle: title,
                    interactionContext: RestrictedSourceInteractionContext.SOURCE_PANE_TOP_BUTTON,
                    referredUrl: href,
                    additionalSources
                  });
                }}
              />
            )}
          </div>
        </div>
        <SuccessComponent
          source={sourceData}
          sourceContentData={sourceContentData}
          sourceContentStatus={sourceContentStatus}
          additionalSources={additionalSources}
        />
      </div>
    </>
  )
}

type SourcePanelNavigationProps = {
  url: string;
  handleOpenSource: () => void;
}

function SourcePanelNavigationButtons({ url, handleOpenSource }: SourcePanelNavigationProps) {
  return (
    <div className="flex mr-5 items-center">
      <Link
        title="Open source in new tab"
        type="button"
        target="_blank"
        className="transition-colors rounded-md text-grey-600 hover:text-blue-400 focus:outline-none focus-visible:ring-2 mr-2"
        to={url}
        onClick={handleOpenSource}
        rel="noopener noreferrer"
      >
        <span className="sr-only">Open source in new tab</span>
        <ArrowTopRightOnSquareIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true"/>
      </Link>
    </div>
  )
}

interface SourceViewSuccessProps {
  source: RestrictedSourceView;
  sourceContentData?: SourceData | null;
  sourceContentStatus: QueryStatus;
  successComponent?: React.ElementType<SourceViewSuccessComponentProps>;
  errorComponent?: React.ElementType;
  loadingComponent?: React.ElementType;
  additionalSources: boolean;
}

function SourcesViewSuccessComponent(props: SourceViewSuccessProps) {
  const {
    source,
    sourceContentData,
    sourceContentStatus,
    successComponent: SuccessComponent = SourceViewSuccessComponent,
    errorComponent: ErrorComponent = SourceViewErrorComponent,
    loadingComponent: LoadingComponent = SourceViewLoadingComponent,
    additionalSources
  } = props;
  if (sourceContentStatus === 'pending') {
    return (
      <>
        <SourceHeader sourceData={source} additionalSources={additionalSources} />
        <LoadingComponent/>
      </>
    )
  }

  if (sourceContentStatus === 'error' || sourceContentData === undefined) {
    return <ErrorComponent/>
  }

  return (
    <SuccessComponent
      data={sourceContentData}
      sourceData={source}
      additionalSources={additionalSources}
    />
  )
}

interface SourceViewSuccessComponentProps {
  data: SourceData | null;
  sourceData: RestrictedSourceView;
  additionalSources: boolean;
}

function SourceViewSuccessComponent(props: SourceViewSuccessComponentProps) {
  const { data, sourceData, additionalSources } = props;
  const { trackSourceViewed } = useAnalyticsEvent();
  const { title, contentType, type: sourceType } = sourceData;

  useEffect(() => {
    trackSourceViewed({ sourceTitle: title, sourceType, contentType, additionalSources });
  }, [data?.id]);

  return (
    <>
      <SourceHeader sourceData={sourceData} additionalSources={additionalSources} />
      <div className="p-6 break-words lg:break-normal flex-1 scroll-pt-8 overflow-y-scroll text-sm">
        <div className="prose [&_*]:!prose-sm prose-pre:whitespace-pre-wrap max-w-full prose-custom-links [&_*]:!font-sans text-sm">
          This source is available to read in Tax Notes. The full text of the source was read in order to generate your answer. Click below to access this source.
        </div>
        <ExternalSourceLink
          data={sourceData}
          contentType={contentType}
          additionalSources={additionalSources}
        />
      </div>
    </>
  )
}
