import { useCallback, useRef } from 'react';
import styles from '../../../index.css?inline';

function createPrintDocument(data: string, title: string) {
  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <title>Ask Blue J - ${title}</title>
        <style type="text/css">
          ${styles}
        </style>
      </head>
      <body class="m-auto mt-8" style="font-family: Inter, sans-serif;">
        ${data}
      </body>
    </html>
  `;
}

export function usePrintPage(title: string, content?: string) {
  const printComponentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useCallback(() => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    if (!iframe.contentWindow || (!content && !printComponentRef.current)) {
      return;
    }

    // pdf name for firefox / safari
    if (iframe.contentDocument) {
      iframe.contentDocument.title = `Ask Blue J${title ? ' - ' + title : ''}`;
    }
    // pdf name for chrome
    iframe.ownerDocument.title = `Ask Blue J${title ? ' - ' + title : ''}`;

    iframe.contentWindow.document.open();

    // Need to wait until print has happened or dialog has been closed before we remove the iframe
    iframe.contentWindow.addEventListener('afterprint', () => {
      document.body.removeChild(iframe);
    });

    const innerHTML = printComponentRef.current?.innerHTML || '';
    iframe.contentWindow?.document.write(createPrintDocument(content ?? innerHTML, title));
    iframe.contentWindow.document.close();
    iframe.contentWindow.print();
  }, [printComponentRef, title, content]);

  return { printComponentRef, handlePrint };
}
