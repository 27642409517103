import React from 'react';
import clsx from 'clsx';
import { TrashIcon } from '@heroicons/react/24/outline';
import { SourceView } from '@/types';

import { ThreadedAskStreamState } from '../../../ask-bluej/streaming/threaded/reducer';
import { QuestionViewContainer } from '../question-view-container';

import { QuestionError } from './error';
import { QuestionInitiated } from './initiated';
import { Answering } from './answering';
import { Question } from './question';
import { AnimatedSources } from './animated-sources';
import { ProgressController } from './progress/progress-controller';
import Progress from './progress/progress';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { ExpectationSetting } from '../../../expectation-setting';
import { replaceInlineCitationsWithLinks } from '../inline-citations/replace-inline-citations';

type ActiveQuestionViewProps = {
  state: ThreadedAskStreamState;
  resubmitQuestion: (question: string, strategy?: string) => void;
  clearAbortedQuestion?: () => void;
}

function AnimatedSourcesComponent({ sources }: { sources: SourceView[] }) {
  const fadeInClassName = clsx('mt-4',{
    'transition-opacity duration-500 ease-in opacity-0': true,
    'opacity-100': sources.length > 0
  })

  return (
    <div className={fadeInClassName}>
      <AnimatedSources sources={sources} />
    </div>

  );

}
export function ActiveQuestionView(props: ActiveQuestionViewProps) {
  const { state, resubmitQuestion, clearAbortedQuestion } = props;
  const [progressComplete, setProgressComplete] = React.useState(false);
  const { trackTryAgainClicked } = useAnalyticsEvent();

  const handleProgressComplete = React.useCallback(() => {
    setProgressComplete(true);
  }, []);

  const handleResubmit = React.useCallback(() => {
    resubmitQuestion(state.payload.question, state.payload.strategy);
    trackTryAgainClicked();
  }, [resubmitQuestion, state.payload.threadId, state.payload.strategy]);

  const questionComponent = (
    <Question
      question={state.payload.question}
      strategy={state.payload.strategy}
    />
  );

  const animatedSourcesComponent = (
    <AnimatedSourcesComponent sources={state.sources} />
  );

  if (state.state === 'initiated' || state.state === 'answering' || state.state === 'complete') {
    if (progressComplete && (state.state === 'answering' || state.state === 'complete')) {
      return (
        <div className="mb-4">
          <QuestionViewContainer questionComponent={questionComponent}>
            <Answering
              isAborted={false}
              isComplete={state.state === 'complete'}
              answer={state.answer}
              sources={state.sources}
            >
              { animatedSourcesComponent }
            </Answering>
          </QuestionViewContainer>
        </div>
      );
    }

    return (
      <div className="mb-4">
        <QuestionViewContainer questionComponent={questionComponent}>
          <QuestionInitiated>
            <ProgressController state={state} onComplete={handleProgressComplete} render={Progress} warningRender={ExpectationSetting} />
            { animatedSourcesComponent }
          </QuestionInitiated>
        </QuestionViewContainer>
      </div>
    );
  }

  if (state.state === 'error') {
    return (
      <QuestionViewContainer questionComponent={questionComponent}>
        <QuestionError
          answer={replaceInlineCitationsWithLinks(state.answer, state.sources)}
          resubmit={handleResubmit}
        />
      </QuestionViewContainer>
    )
  }

  if (state.state === 'aborted') {
    // "Delete" button to clear the aborted question from view
    const deleteButton = (
      <div className="hidden lg:flex flex-row self-center w-12 align-middle">
        <button className="text-grey-600 hover:text-blue-400 items-center" onClick={clearAbortedQuestion}>
          <TrashIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block" aria-hidden={true} />
        </button>
      </div>
    );

    const abortedQuestionComponent = (
      <>
        {questionComponent}
        {deleteButton}
      </>
    );
    return (
      <QuestionViewContainer questionComponent={abortedQuestionComponent}>
        <Answering
          isAborted={true}
          isComplete={true}
          answer={state.answer}
          sources={state.sources}
        >
          <div className="self-center mb-4">
            <p><em>Answer generation stopped</em></p>
          </div>
          { animatedSourcesComponent }
        </Answering>
      </QuestionViewContainer>
    )
  }
}

