import { animated, easings, useSpring } from '@react-spring/web';

import { PROGRESS_BAR_COMPLETE_ANIMATION_MS } from './shared';

export default function Progress(props: Props) {
  const { step, progress } = props;

  const springProps = useSpring({ width: `${progress}%`, config: (
    // We want to use a fixed duration animation for our final state so that we can time
    //   the animation with the state machine's complete delay. For other states we want
    //   a nice fluid physics based animation.
    // Config Visualizer: https://react-spring.dev/docs/advanced/config#config-visualizer
    // Easings: https://easings.net/
    progress === 100 ?
      {
        easing: easings.easeOutCubic,
        duration: PROGRESS_BAR_COMPLETE_ANIMATION_MS,
      } : {
        friction: 18,
        tension: 140
      }
    )
  });

  const stepText = STEP_STRINGS[step] ?? step;

  // TODO: Animate ellipsis?
  //    Was being shown with `after:content-['...']` before, what's easier to animate?
  const responseText= stepText.length ? `${stepText}...` : '';

  return (
    <div className="flex flex-col flex-grow gap-2">
      <div className="text-grey-700 flex after:inline-block after:w-0 after:overflow-hidden after:animate-ellipsis-load">
        {responseText}
      </div>
      <div className="bg-gray-100 rounded-lg h-1" role="progressbar">
        <animated.div style={springProps}>
          <div className="bg-blue-500 rounded-lg h-1 text-center animate-pulse transition-all ease-in-out duration-200" />
        </animated.div>
      </div>
    </div>
  )
}

const STEP_STRINGS: Record<string, string> = {
  processing_request: 'Processing request',
  searching: 'Searching content',
  processing_documents: 'Processing documents',
  generating_answer: 'Generating answer'
}

type Props = {
  step: string;
  progress: number;
}
