import { Transition } from '@headlessui/react';
import React, { useCallback, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { removeHtmlTags } from '../../utils/sanitization/santization';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { FaqInteractionContext } from '../thread/types';
import { getCriteriaBasedCautionaryText } from './get-text-based-on-criteria';

type ExpectationSettingMessageProps = {
  criteria?: string;
  visible: boolean;
};

export const ExpectationSettingMessage = ({ criteria, visible }: ExpectationSettingMessageProps) => {
  const { trackFaqClicked, trackExpectationMessageViewed } = useAnalyticsEvent();

  const handleFaqClick = useCallback(() => {
    return trackFaqClicked(FaqInteractionContext.EXPECTATION_MESSAGE);
  }, []);

  const criteriaText = criteria ? getCriteriaBasedCautionaryText(criteria, handleFaqClick) : null;

  useEffect(() => {
    if (criteria && criteriaText) {
      trackExpectationMessageViewed({
        messageText: `${criteriaText.title} ${removeHtmlTags(renderToString(criteriaText.text))}`,
        messageType: criteria,
      })
    }
  }, [criteria]);

  return (
    <Transition
      as="div"
      className="bg-yellow-100 border border-grey-400 rounded-2xl text-black-400 px-4 py-3 mt-3"
      role="alert"
      show={visible && !!criteria && !!criteriaText}
      enter="transition-all ease-in-out duration-500 delay-[200ms]"
      enterFrom="opacity-0 translate-y-6"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <p className="mb-2 font-bold text-sm">{criteriaText?.title}</p>
        <p className="text-sm">{criteriaText?.text}</p>
      </div>
    </Transition>
  )
}
