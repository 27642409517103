import { SourceView } from '@/types';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { PropsWithChildren, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { SourceLink } from './source-link';

interface SourcesPagerProps {
  number: number;
  sourceType: SourceView['type'];
  sources: SourceView[];
  additionalSources: boolean;
}

interface PagerLinkProps {
  enabled: boolean;
  number: number;
  onClick: () => void;
  title: string;
  additionalSource: boolean;
}

function PagerButton(props: PropsWithChildren<PagerLinkProps>) {
  const { enabled, number, children, onClick, title, additionalSource } = props;

  if (enabled) {
    return (
      <SourceLink number={number} additionalSource={additionalSource}>
        { ({ url }) => (
          <Link className="text-gray-600 hover:text-blue-400 focus:outline-none focus-visible:ring-2" to={url} replace={true} onClick={onClick} title={title}>
            <>
              <span className="sr-only">{title}</span>
              { children }
            </>
          </Link>
        )}
      </SourceLink>
    )
  }

  return (
    <>
      <span className="text-gray-400">
        { children }
      </span>
    </>
  );
}

export function SourcesPager(props: SourcesPagerProps) {
  const { number, sourceType, sources, additionalSources } = props;
  const { trackSourceNavigationClicked } = useAnalyticsEvent();

  const nextIndex = number + 2;
  const currentIndex = number + 1;
  const totalSources = sources.length;

  const handleOnNavigationClick = useCallback(() => {
    trackSourceNavigationClicked({ sourceType, additionalSources });
  }, [sourceType]);

  return (
    <div className="flex flex-row gap-2 items-center">
      <PagerButton
        title="Previous source"
        enabled={number > 0}
        number={number}
        onClick={handleOnNavigationClick}
        additionalSource={additionalSources}
      >
        <ArrowLeftIcon className="w-7 h-7 lg:w-6 lg:h-6"/>
      </PagerButton>
      <span className="inline-block font-semibold">
        {currentIndex}/{totalSources}
      </span>
      <PagerButton
        title="Next source"
        enabled={currentIndex < totalSources}
        number={nextIndex}
        onClick={handleOnNavigationClick}
        additionalSource={additionalSources}
      >
        <ArrowRightIcon className="w-7 h-7 lg:w-6 lg:h-6"/>
      </PagerButton>
    </div>
  )
}
