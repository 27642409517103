import { stringify } from 'qs';

export const AUTH_HEADERS = {
  'X-Ask-Blue-J-Request': 'true'
};

export type Methods = 'POST' | 'GET' | 'DELETE' | 'PUT';

type RouteGeneratorFn<TParams = never> = (params: TParams) => string;
type AuthFailedCallback = () => void;

export class ResponseError extends Error {
  public status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
  }
}

function createUrl<RouteParams>(apiSubRoute: string | (RouteGeneratorFn<RouteParams>), payload?: RouteParams) {
  let url;

  if (typeof apiSubRoute === 'string') {
    url = apiSubRoute
  } else if (typeof apiSubRoute === 'function' && payload) {
    url = apiSubRoute(payload);
  }

  if (!url) {
    throw new Error('Unable to make request, URL not provided or unable to generate');
  }

  return url;
}

export abstract class Api {
  protected constructor(protected readonly backendUrl = '') {}

  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  protected apiFetch(url: string, requestParams: RequestInit, signal?: AbortSignal): Promise<Response> {
    return fetch(`${this.backendUrl}${url}`, {
      ...requestParams,
      credentials: 'include',
      headers: {
        ...this.baseHeaders(),
        ...AUTH_HEADERS,
        ...(requestParams.headers || {})
      },
      signal
    });
  }

  protected createFetch<TPayload, TResponse, TParams>(apiSubRoute: string | (RouteGeneratorFn<TParams>), method: Methods = 'POST') {
    return async (payload?: TPayload, params?: TParams): Promise<TResponse> => {
      const requestParams: RequestInit = {
        method
      };

      let url = createUrl(apiSubRoute, params);

      if (payload) {
        if (method === 'POST' || method === 'PUT') {
          requestParams.body = JSON.stringify(payload);
        } else if (method === 'GET') {
          url = `${url}${stringify(payload, { addQueryPrefix: true })}`;
        }
      }

      const response = await this.apiFetch(url, requestParams);

      // TODO: Validate response!!

      return await response.json();
    };
  }

  public generateAuthLink(apiUrl: string, returnToUrl: string, action: 'login' | 'logout'): string {
    return `${apiUrl}${action}?url=${encodeURIComponent(returnToUrl)}`
  }
}

export abstract class DownstreamApi extends Api {
  constructor (protected readonly backendUrl = '') {
    super(backendUrl);
  }

  protected apiFetch(url: string, requestParams: RequestInit): Promise<Response> {
    return super.apiFetch(url, requestParams)
      .then((response: Response) => {
        if (!response.ok) {
          // TODO:
          //   - Create custom error types
          //   - Map status to appropriate error and throw
          throw new ResponseError(`Api Fetch Failed: ${response.status}`, response.status);
        }
        return response;
      })
  }
}

export abstract class AuthGuardApi extends Api {
  public onAuthFailedCallBack: AuthFailedCallback;

  constructor (protected readonly backendUrl = '', apiUrl: string) {
    super(backendUrl);

    this.onAuthFailedCallBack = () => {
      window.location.href = this.generateAuthLink(apiUrl, window.location.href, 'login');
    }
  }

  protected apiFetch(url: string, requestParams: RequestInit, signal?: AbortSignal): Promise<Response> {
    return super.apiFetch(url, requestParams, signal)
      .then(async (response) => {
        if (!response.ok) {
          const text = await response.text();
          // TODO:
          //   - Create custom error types
          //   - Map status to appropriate error and throw

          if (response.status === 401 || response.status === 403) {
            if (!text.includes('does not belong to user')){
              this.onAuthFailedCallBack && this.onAuthFailedCallBack();
            }
          }

          throw new ResponseError(`Api ${url} Fetch Failed: ${response.status}`, response.status);
        }
        return response
      });
  }

}
