import { Thread } from '@/types';
import { ErrorBoundary } from '@sentry/react';
import { InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { useStrategyResolver } from '../../ask-bluej/strategy-resolver';
import { ThreadedAskStreamAbortedState, ThreadedAskStreamState } from '../../ask-bluej/streaming/threaded/reducer';
import { QuestionInteractionType, QuestionPayload } from '../../ask-bluej/streaming/threaded/shared';
import { useThreadResolver } from '../../ask-bluej/thread-resolver';
import { useAskBluejApiContext } from '../../core/api/ask-bluej-api';
import { ErrorFallback } from '../../core/errors/error-fallback';
import { GlobalAnalyticsContextProvider } from '../contexts/GlobalAnalyticsContext';
import { ThreadContextProvider, ThreadContextValue, useThreadContext } from '../contexts/ThreadContext';
import { useFollowUpQuestions } from '../hooks/useFollowUpQuestions';
import { useGetScrollBarWidth } from '../hooks/useGetScrollBarWidth';
import { useGetChatQuery } from '../useGetChatQuery';
import { useThreadListQuery } from '../useThreadListQuery';
import { useThreadQuery } from '../useThreadQuery';
import { useThreadUpdateQuery } from '../useThreadUpdateQuery';

import { AbortedChat } from './aborted-chat';
import { ActiveQuestionInitiator } from './active-question-view/active-question-initiator';
import { ThreadChatList } from './chats/thread-chat-list';
import { ThreadChatLoader } from './chats/thread-chat-loader';
import { FollowUpQuestions } from './follow-up-questions';
import { MobileHeader } from './mobile-header';
import { Placeholder } from './placeholder';
import { ThreadedQuestionInput } from './prompting/threaded-question-input';
import { ThreadError } from './thread-error-message';

/**
 * TODO:
 *    - Lift the thread chats list out of the Streaming/Non-Streaming components
 *    - Pass component for input and active question as a child or wrapped with component
 *    - Lets us iterate on our "omnibox" input in an easier way
 *    - React.children.map and extract to slot into place?
 *    - Rename components, "ThreadSuccess" doesn't really mean anything
 */

type ThreadViewProps = {
  threadId: string;
}

export const ThreadView = ({ threadId }: ThreadViewProps) => {
  const {
    status,
    data,
    error,
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useThreadQuery(threadId);

  if (status === 'pending') {
    return (
      <div className="flex-1 overflow-y-scroll px-4 md:px-10 pt-4 scroll-pb-4">
        <div className="max-w-5xl mx-auto">
          <div className="space-y-12">
            <Placeholder count={3}>
              <ThreadChatLoader />
            </Placeholder>
          </div>
        </div>
      </div>
    )
  }

  if (status === 'error') {
    return <ThreadError error={error} handleRetry={refetch}/>;
  }

  if (data === undefined) {
    // TODO: Create a better view for this
    return <div>Thread not found</div>;
  }

  const threadContextValue: ThreadContextValue = {
    thread: {
      ...data.pages[0],
      chats: data.pages.flatMap((thread) => thread.chats)
    },
  };

  return (
    <GlobalAnalyticsContextProvider value={{ threadId }}>
      <ThreadContextProvider value={threadContextValue}>
        <MobileHeader threadTitle={data.pages[0].title} />
        <ErrorBoundary fallback={<ErrorFallback errorText="Error loading thread." />}>
          <ThreadSuccess
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ErrorBoundary>
      </ThreadContextProvider>
    </GlobalAnalyticsContextProvider>
);
}

type ThreadSuccessProps = {
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<Thread>, Error>>;
  hasNextPage: boolean;
};

function ThreadSuccess({ isFetching, isFetchingNextPage, fetchNextPage, hasNextPage }: ThreadSuccessProps) {
  const { api } = useAskBluejApiContext();
  const { thread } = useThreadContext();

  const navigate = useNavigate();
  const [questionInProgress, setQuestionInProgress] = useState(false);
  const [questionPayload, setQuestionPayload] = useState<null | QuestionPayload>(null);
  const currentQuestionKey = JSON.stringify(questionPayload);
  const { getChat } = useGetChatQuery();
  const { generateThreadTitle } = useThreadUpdateQuery(thread.id);
  const { refetch: refetchThreadList } = useThreadListQuery();
  const strategyResolver = useStrategyResolver();
  const threadResolver = useThreadResolver();
  const [abortedQuestion, setAbortedQuestion] = useState<ThreadedAskStreamAbortedState | null>(null);
  const [interactionContext, setInteractionContext] = useState<null | QuestionInteractionType>(null);
  const { outerContainerRef, innerContainerRef, scrollbarWidth } = useGetScrollBarWidth();
  const {
    questionAskedAtLeastOnce,
    setQuestionAskedAtLeastOnce,
    completedChat,
    setCompletedChat,
    showFollowUpQuestions
  } = useFollowUpQuestions(questionInProgress, abortedQuestion);

  let skipRenderActiveQuestion = false;
  if (questionPayload && thread.chats.findIndex(chat => chat.chat_id === questionPayload.chatId) !== -1) {
    skipRenderActiveQuestion = true;
  }

  useEffect(() => {
    const search = (new URL(window.location.href)).searchParams;
    if (search.has('question')) {
      const question = search.get('question') as string;
      const strategy = search.get('strategy') as string;
      const interactionType = search.get('interactionContext') as QuestionInteractionType;
      navigate(`/${thread.id}`, { replace: true });
      void handleSubmit(question, interactionType, strategy);
    }
  }, []);

  const handleAbort = useCallback((state: ThreadedAskStreamAbortedState) => {
    setQuestionPayload(null);
    setQuestionInProgress(false);
    setAbortedQuestion(state);
  }, []);

  const handleSubmit = useCallback(async (question: string, interactionContext: QuestionInteractionType | null, strategy?: string) => {
    // TODO: Retry w/ the same uuid?
    // I'm wondering if the backend should generate a chatId and send it back
    setQuestionInProgress(true);
    setAbortedQuestion(null);
    setCompletedChat(null);

    const questionStrategy = strategy || await strategyResolver(thread.topic);
    const threadId = await threadResolver(thread.id);

    if (questionStrategy && threadId) {
      toast.dismiss('ask-error');
      const questionPayload = {
        chatId: uuid(),
        question,
        strategy: questionStrategy || thread.topic,
        threadId
      };
      setQuestionPayload(questionPayload);
      setInteractionContext(interactionContext);

      return true;
    }

    if (!threadId) {
      toast.error('Thread not found. Please try asking your question again.', {
        toastId: 'ask-error'
      });
      await refetchThreadList();
      navigate('/', { state: { question } });
    } else {
      toast.error('A problem occurred while submitting question. Wait a moment and try again.', {
        toastId: 'ask-error'
      });
    }
    setQuestionInProgress(false);
    return false;
  }, [thread.id, api]);

  const handleActiveQuestionComplete = useCallback(async (state: ThreadedAskStreamState) => {
    if (state.state === 'complete') {

      // This is to start showing the follow-up questions only after we asked a question and received an answer
      // We don't want to show them if we're refreshing the page, switching threads, viewing for the first time, etc.
      if (!questionAskedAtLeastOnce) {
        setQuestionAskedAtLeastOnce(true);
      }
      await getChat(state.payload.threadId, state.payload.chatId)
        .then((res) => {
          setCompletedChat(res);
        })
        .catch(() => {
          // If we didn't get it, worse case we're going to flicker... it'll get requested again.
        })
        .then(() => {
          // We need the chat in the thread cache before we can generate the title
          generateThreadTitle(state.payload.chatId);
        });

      setQuestionPayload(null);
    }
    setQuestionInProgress(false);
  }, [getChat]);

  const handleClearAbortedQuestion = useCallback(() => {
    setAbortedQuestion(null);
  }, []);

  return (
    <ThreadChatList
      key={thread.id}
      activeQuestionView={(
        <>
          <ActiveQuestionInitiator
            key={currentQuestionKey}
            payload={skipRenderActiveQuestion ? null : questionPayload}
            onComplete={handleActiveQuestionComplete}
            resubmitQuestion={handleSubmit}
            onAbort={handleAbort}
            interactionContext={interactionContext}
          />
          <AbortedChat state={abortedQuestion} clearAbortedQuestion={handleClearAbortedQuestion} />
        </>
      )}
      questionInputView={(
        <div className="px-4 md:px-10 py-4 border-t border-top-grey-300" style={{ marginRight: `${scrollbarWidth}px` }}>
          <ThreadedQuestionInput
            onSubmit={handleSubmit}
            disabled={questionInProgress}
            variant="condensed"
            autoFocusUserInput={true}
            existingThreadTopic={thread.topic}
            numChats={thread.chats.length}
          />
        </div>
      )}
      followUpQuestionView={(
        <FollowUpQuestions
          show={showFollowUpQuestions}
          handleSelectQuestion={handleSubmit}
          chat={completedChat}
        />
      )}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      outerContainerRef={outerContainerRef}
      innerContainerRef={innerContainerRef}
    />
  )
}
