import { SourceData, SourceView } from '@/types';

import { DownstreamApi } from '../core/api/api';
import { removeHtmlTags, sanitizeHtml } from '../../utils/sanitization/santization';

export interface ISourcesApi {
  getSourceContent: (id: string, type: string) => Promise<SourceData>;
  getAdditionalSources: (threadId: string, chatId: string) => Promise<SourceView[]>;
}

export class SourcesApi extends DownstreamApi implements ISourcesApi {
  private basePath = 'api';

  private _getSourceContent = this.createFetch<null, SourceData, { id: string, type: string }>((params) => {
    const queryParams = new URLSearchParams({ id: params.id.split('?')[0], type: params.type });
    return `${this.basePath}/content-management/content?${queryParams.toString()}`;
  }, 'GET');

  private _getAdditionalSources = this.createFetch<null, SourceView[], { threadId: string, chatId: string }>(params => `${this.basePath}/additional-sources/${params.threadId}/${params.chatId}`, 'GET');

  public async getSourceContent(id: string, type: string): Promise<SourceData> {
    return this._getSourceContent(null, { id, type }).then(async (result) => {
      const SANITIZE_TYPES = ['case', 'document'];

      if (SANITIZE_TYPES.includes(result.type)) {
        result.content = await sanitizeHtml(result.content);
        result.description = removeHtmlTags(result.description);
      }

      return result;
    });
  }

  public async getAdditionalSources(threadId: string, chatId: string) {
    return this._getAdditionalSources(null, { threadId, chatId });
  }
}
