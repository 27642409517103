import { AvailableSourceView, SourceData } from '@/types';
import { Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { QueryStatus } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '../../../../components/button';
import { AskDocInteractionType } from '../../../ask-bluej/streaming/doc/shared';
import { AskDocument } from '../../../ask-doc/components/ask-doc';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { replaceLinks } from '../../../source/utils/replace-links';
import { useSharedScrollEventListener } from '../../hooks/useSharedScrollEventListener';
import { SourceHighlighter } from '../../source-highlighter/source-highlighter';
import { Placeholder } from '../placeholder';
import { PrintSource } from './print-source';
import { SourceDescription } from './source-description';
import { SourceHeader } from './source-header';
import { usePrintSourceData } from './usePrintSourceData';
import { RestrictedSourceInteractionContext } from '../../types';
import { ExternalLinkClickTracker } from '../../../source/components/source-highlighter/external-link-click-tracker';
import { backCompatUrl } from './external-badge';

interface SourceViewProps {
  successComponent?: React.ElementType;
  sourceData: AvailableSourceView;
  sourceNavigationComponent?: React.ReactElement;
  additionalSources?: boolean;
}

export function SourcesView(props: SourceViewProps) {
  const {
    successComponent: SuccessComponent = SourcesViewSuccessComponent,
    sourceData,
    sourceNavigationComponent,
    additionalSources = false
  } = props;
  const { trackSourceOpenInNewTabClicked, trackSourcePrintClicked, trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();

  const { displayType, type, content, href, id, externalUrl, title } = sourceData;
  const {
    printData,
    printComponentRef,
    handlePrint,
    sourceContent,
    sourceContentData,
    sourceContentStatus
  } = usePrintSourceData(sourceData);

  const headerClasses = clsx('flex mb-2', {
    'justify-between': sourceNavigationComponent,
    'justify-end': !sourceNavigationComponent,
  });

  const handleOpenSource = useCallback(() => {
    if (externalUrl) {
      trackRestrictedSourceOpenInNewTabClicked({
        sourceType: sourceData.displayType,
        sourceTitle: title,
        interactionContext: RestrictedSourceInteractionContext.SOURCE_PANE_TOP_BUTTON,
        referredUrl: externalUrl || href,
        additionalSources
      });

      return;
    }

    trackSourceOpenInNewTabClicked({
      sourceType: type,
      sourceTitle: sourceData.title,
      additionalSources
    });
  }, [additionalSources, displayType, externalUrl, href, title]);

  return (
    <>
      <div className="flex flex-col overflow-hidden w-full">
        <div className="px-6 pt-6">
          <div className={headerClasses}>
            {sourceNavigationComponent}
            <SourcePanelNavigationButtons
              id={id}
              type={type}
              url={href}
              externalUrl={externalUrl}
              handlePrint={() => {
                trackSourcePrintClicked({ sourceType: type, sourceTitle: sourceData.title, additionalSources });
                setTimeout(() => handlePrint(), 1);
              }}
              showPrintButton={!!sourceData && !sourceData.externalUrl}
              handleOpenSource={handleOpenSource}
            />
          </div>
        </div>
        <SuccessComponent
          highlightContent={content}
          source={sourceData}
          sourceContentData={sourceContentData}
          sourceContentStatus={sourceContentStatus}
          additionalSources={additionalSources}
        />
      </div>
      <PrintSource
        ref={printComponentRef}
        printData={printData}
        source={sourceContent}
        content={content}
        sourceData={sourceData}
      />
    </>
  )
}

type SourcePanelNavigationProps = {
  externalUrl: string | null;
  type: AvailableSourceView['type'];
  id: AvailableSourceView['id'];
  url: string;
  handlePrint: () => void;
  showPrintButton?: boolean;
  handleOpenSource: () => void;
}

function SourcePanelNavigationButtons({
  externalUrl,
  type,
  id,
  handlePrint,
  showPrintButton,
  handleOpenSource
}: SourcePanelNavigationProps) {
  let href = '';

  if (externalUrl) {
    href = externalUrl;
  } else {
    // FIXME (backwards-compat) remove once we adjust the BE link resolution
    href = getSourceHref(id, type);
  }

  return (
    <div className="flex mr-5 items-center">
      {showPrintButton && (
        <Button
          className="hidden lg:block mr-2 text-grey-600 hover:text-black-600"
          colour="secondary"
          onClick={handlePrint}
          title="Print"
          variant="icon"
        >
          <PrinterIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true"/>
        </Button>
      )}
      <Link
        title="Open source in new tab"
        type="button"
        target="_blank"
        className="transition-colors rounded-md text-grey-600 hover:text-blue-400 focus:outline-none focus-visible:ring-2 mr-2"
        to={backCompatUrl(href)}
        onClick={handleOpenSource}
        rel="noopener noreferrer"
      >
        <span className="sr-only">Open source in new tab</span>
        <ArrowTopRightOnSquareIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true"/>
      </Link>
    </div>
  )
}

function getSourceHref(id: SourceData['id'], type: AvailableSourceView['type']): string {
  // cases and documents aren't yet in CAM
  if (type === 'case' || type === 'document') {
    return `/source/${type}/${id}`;
  }

  const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id);

  // newer CAM stuff is always a UUID
  if (isUuid) {
    return `/source/${id}`;
  }

  // older S&R support
  if (type === 'statute' || type === 'regulation') {
    const parts = id.split('/');
    const title = parts[1];
    const sectionNumber = parts[3].split('?')[0];
    return `/source?ref=${type}/${title}/${sectionNumber}`;
  }

  // FIXME (backwards-compat)
  //  but some of the old chats have old content_source_ids, but the type is updated coming through the backend
  if (type === 'curator_case' || type === 'curator_document') {
    return `/source?ref=${type}/${id}`;
  }

  // everything else needs to backwards-compat
  return `/source?ref=${id}`;
}

interface SourceViewSuccessProps {
  highlightContent: string;
  source: AvailableSourceView;
  sourceContentData?: SourceData | null;
  sourceContentStatus: QueryStatus;
  successComponent?: React.ElementType<SourceViewSuccessComponentProps>;
  errorComponent?: React.ElementType;
  loadingComponent?: React.ElementType;
  additionalSources: boolean;
}

function SourcesViewSuccessComponent(props: SourceViewSuccessProps) {
  const {
    source,
    highlightContent,
    sourceContentData,
    sourceContentStatus,
    successComponent: SuccessComponent = SourceViewSuccessComponent,
    errorComponent: ErrorComponent = SourceViewErrorComponent,
    loadingComponent: LoadingComponent = SourceViewLoadingComponent,
    additionalSources
  } = props;
  if (sourceContentStatus === 'pending') {
    return (
      <>
        <SourceHeader sourceData={source} additionalSources={additionalSources} />
        <LoadingComponent/>
      </>
    )
  }

  if (sourceContentStatus === 'error' || sourceContentData === undefined) {
    return <ErrorComponent/>
  }

  return (
    <SuccessComponent
      highlightContent={highlightContent}
      data={sourceContentData}
      sourceData={source}
      additionalSources={additionalSources}
    />
  )
}

// TODO: Rename all of these, they're confusing
interface SourceViewSuccessComponentProps {
  data: SourceData | null;
  highlightContent: string;
  sourceData: AvailableSourceView;
  additionalSources: boolean;
}

function SourceViewSuccessComponent(props: SourceViewSuccessComponentProps) {
  const { data, highlightContent, sourceData, additionalSources } = props;
  const { trackRestrictedSourceOpenInNewTabClicked, trackSourceViewed } = useAnalyticsEvent();
  const { displayType, externalUrl, href, title: sourceTitle, contentType, type: sourceType } = sourceData;
  const { ref, emitter, skipNextEmit } = useSharedScrollEventListener();
  const { state } = useLocation();
  const [isQuickSummarize, setIsQuickSummarize] = useState(state?.context === 'quick-summarize');

  // TODO (backwards-compat) clean this up
  const tempRestrictedSourceClickHandler = useCallback((referredUrl: string) => {
    trackRestrictedSourceOpenInNewTabClicked({
      sourceType: displayType,
      sourceTitle,
      interactionContext: RestrictedSourceInteractionContext.VIEW_IN_TAX_NOTES, // TODO There is only one, this could be wrong in the future
      referredUrl,
      additionalSources
    });
  }, [displayType, sourceTitle, externalUrl, href, additionalSources ]);

  useEffect(() => {
    if (isQuickSummarize) {
      const stateCopy = { ...state };
      delete stateCopy.context;
      window.history.replaceState({ state: stateCopy }, '');
    }
  }, [state]);

  useEffect(() => {
    // FIXME this also needs to track an "interactionContext" - whether the user is viewing from a link click or from paging through documents
    trackSourceViewed({ sourceTitle, sourceType, contentType, additionalSources });
  }, [data?.id]);

  const description = renderToString(<SourceDescription data={data} />);
  const content = description ? `<div>${description}${data?.content}</div>` : (data?.content ?? '');

  const initialState = localStorage.getItem(`ask-${AskDocInteractionType.PANE}-tooltip`) !== 'true';
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <SourceHeader
        sourceData={sourceData}
        additionalSources={additionalSources}
        emitter={emitter}
      />
      <div className="p-6 break-words lg:break-normal flex-1 scroll-pt-8 overflow-y-scroll" ref={ref}>
        <div className="prose [&_*]:custom-prose-sm prose-pre:whitespace-pre-wrap max-w-full prose-custom-links [&_*]:!font-sans">
          <ExternalLinkClickTracker onExternalLinkClicked={tempRestrictedSourceClickHandler}>
            <SourceHighlighter
              content={highlightContent}
              source={content}
              onContentReady={replaceLinks}
              skipNextEmit={skipNextEmit}
            />
          </ExternalLinkClickTracker>
        </div>
      </div>
      {data && (
        <>
          <div className="bg-transparent h-[72px]"/>
          <Transition
            appear={true}
            show={true}
            enter="transition transform ease-in duration-700"
            enterFrom="translate translate-y-full"
            enterTo="translate translate-y-0"
            afterEnter={() => setTooltipVisible(initialState)}
          >
            <div className="fixed right-0 bottom-0 w-full max-w-[1000px] lg:w-[50vw] border-t-2 border-blue-400 bg-gray-50">
              <AskDocument
                content={content}
                interactionContext={isQuickSummarize ? AskDocInteractionType.QUICK_SUMMARIZE : AskDocInteractionType.PANE}
                sourceData={data}
                setIsQuickSummarize={setIsQuickSummarize}
                docQuestionTooltip={{ isTooltipVisible, setTooltipVisible }}
                additionalSources={additionalSources}
              />
            </div>
          </Transition>
        </>
      )}
    </>
  )
}

export function SourceViewErrorComponent() {
  return (
    <div>Error loading source content</div>
  )
}

export function SourceViewLoadingComponent() {
  return (
    <div className="p-6 animate-pulse">
      <div className="grid gap-2 grid-cols-1">
        <div className="w-60">
          <div className="rounded-sm h-4 bg-slate-200"/>
        </div>
        <div className="w-80">
          <div className="rounded-sm h-4 bg-slate-200"/>
        </div>
        <Placeholder count={3}>
          <div className="rounded-sm h-4 bg-slate-200"/>
        </Placeholder>
        <div className="mt-12 grid gap-3 grid-cols-1">
          <Placeholder count={20}>
            <div className="rounded-sm h-4 bg-slate-200"/>
          </Placeholder>
        </div>
      </div>
    </div>
  )
}
