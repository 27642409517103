import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import { PageTitle } from '../core/app/page-title';
import { ErrorFallback } from '../core/errors/error-fallback';
import { useStrategyQuery } from '../core/strategy/useStrategyQuery';
import { useUser } from '../core/user/context';
import { MobileHeader } from '../thread/components/mobile-header';
import { FollowUpQuestionsSetting } from './follow-up-questions-setting';
import { TopicSetting } from './topic-setting';

export function SettingsRoute() {
  const { user, preferences, preferencesDataStatus } = useUser();
  const { preferredTopic: currentTopic, preferredFollowUpOption = true } = preferences ?? {};
  const { data, isLoading, isError } = useStrategyQuery();

  if (isLoading || preferencesDataStatus === 'pending') {
    return null;
  }

  const SettingsError = <ErrorFallback errorText="Error loading settings." />;

  if (isError || data === undefined || preferencesDataStatus === 'error') {
    return (
      <>
        <MobileHeader />
        {SettingsError}
      </>
    );
  }

  const { topics } = data;

  return (
    <div className="flex self-start w-full h-full flex-col">
      <PageTitle title="Account settings" />
      <MobileHeader />
      <ErrorBoundary fallback={SettingsError}>
        <div className="p-4 lg:p-6 items-center justify-center border-b border-b-grey-300">
          <h1 className="font-bold w-full text-lg lg:text-xl text-flatBlue-400 mt-[1px] mb-[1px]">Account settings</h1>
        </div>
        <div className="mx-2 md:mx-4 lg:mx-8">
          <div className="overflow-y-scroll flex-1 my-4 w-full max-w-full lg:max-w-4xl mx-auto">
            <div className="p-4 lg:p-8 border border-grey-400 rounded-xl">
              <h2 className="text-md lg:text-lg font-semibold mb-4">
                Your profile
              </h2>

              <div className="flex flex-col md:table w-full mb-2">
                <div className="table-row text-grey-500">
                  <div className="table-cell text-sm lg:text-md pb-4">Name</div>
                  <div className="table-cell text-sm lg:text-md pb-4">Email</div>
                  <div className="table-cell text-sm lg:text-md pb-4">Role</div>
                </div>

                <div className="table-row">
                  <div className="table-cell text-sm lg:text-md">{user.firstName} {user.lastName}</div>
                  <div className="table-cell text-sm lg:text-md">{user.email}</div>
                  <div className="table-cell text-sm lg:text-md">{user.isAdmin ? 'Admin' : 'User'}</div>
                </div>
              </div>
              {currentTopic && (
                <>
                  <TopicSetting
                    currentTopic={currentTopic}
                    topics={topics}
                    preferredFollowUpOption={preferredFollowUpOption}
                  />
                  <FollowUpQuestionsSetting preferredTopic={currentTopic} />
                </>
              )}

            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}
