import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { Topic } from '@/types';
import { useUser } from '../core/user/context';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { Button } from '../../components/button';

type TopicSettingProps = {
  currentTopic: string;
  topics: Topic[];
  preferredFollowUpOption: boolean;
}

export function TopicSetting(props: TopicSettingProps) {
  const { currentTopic, topics, preferredFollowUpOption } = props;
  const { updateUserPreferences } = useUser();
  const { trackDataSourceEditClicked, trackDataSourceEditCancelled, trackDataSourceEditSaved } = useAnalyticsEvent();
  const [showEditTopicModal, setShowEditTopicModal] = useState(false);

  const handleEditTopicClick = useCallback(() => {
    trackDataSourceEditClicked();
    setShowEditTopicModal(true);
  }, []);
  const handleCancelTopicClick = useCallback(() => {
    trackDataSourceEditCancelled();
    setShowEditTopicModal(false);
  }, []);
  const handleSaveTopicClick = useCallback((newTopicValue: string) => {
    trackDataSourceEditSaved(newTopicValue, currentTopic, newTopicValue !== currentTopic)
    updateUserPreferences({ preferredTopic: newTopicValue, preferredFollowUpOption })
      .then(() => {
        toast.success('Tax data source updated');
      })
      .catch(() => {
        toast.error('Failed to update tax data source');
      });
    setShowEditTopicModal(false);
  }, [preferredFollowUpOption, currentTopic]);

  return (
    <>
      <h2 className="text-md font-semibold mb-4 mt-8">
        Tax data source
      </h2>

      <div className="table w-full mb-2">
        <div
          className="table-cell text-sm lg:text-md">{topics.find(topic => topic.value === currentTopic)?.displayName}
        </div>
        <div className="table-cell text-right">
          <Button
            className="bg-transparent hover:bg-transparent !text-blue-400 !font-normal hover:underline hover:underline-offset-4 text-sm"
            colour="primary"
            onClick={handleEditTopicClick}
            title="Edit User Topic"
            variant="icon"
          >
            Edit
          </Button>
        </div>
      </div>
      <EditTaxDataSourceModal
        currentTopic={currentTopic}
        onClose={handleCancelTopicClick}
        onSave={(newTopicValue) => handleSaveTopicClick(newTopicValue)}
        visible={showEditTopicModal}
        topics={topics}
      />
    </>
  );
}

type ModalProps = {
  currentTopic: string;
  visible: boolean;
  onClose: () => void;
  onSave: (newTopicValue: string) => void;
  topics: Topic[];
}

function EditTaxDataSourceModal({ currentTopic, onClose, onSave, visible, topics }: ModalProps) {
  const [topic, setTopic] = useState(currentTopic);
  return (
    <Dialog open={visible} className="relative z-50" onClose={onClose}>
      <div tabIndex={-1} className="backdrop-brightness-50 fixed z-50 inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center text-center p-4">
          <div className="relative overflow-hidden text-left sm:my-8 bg-white rounded-lg shadow dark:bg-gray-700 p-6 w-full lg:w-[50vw] max-w-[600px]">
            <div className="mb-">
              <div className="font-semibold mb-6 font-lg">Tax data source</div>
              <div className="flex flex-col gap-2 mb-6">
                { topics.map(({ displayName, value }) => {
                  return (
                    <div className="flex flex-row gap-2 items-center" key={`tax-data-source-${displayName}}`}>
                      <input className="text-blue-400 focus:ring-blue-400" id={value} type="radio" value={value} onChange={(event) => setTopic(event.currentTarget.value)} name="topic" checked={topic === value} />
                      <label htmlFor={value}>{ displayName }</label>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex gap-4">
              <Button colour="primary" title="Save" onClick={() => onSave(topic)} variant="contained" className="p-2">Save</Button>
              <Button colour="secondary" title="Cancel" onClick={onClose} variant="icon" className="!font-normal">Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
