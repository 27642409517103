import { SourceView } from '@/types';
import { EventSourceActions } from '@bluejlegal/event-source-reducer';

import { sortSourceByDisplayType } from '../../../source/utils/sort-source-by-display-type';
import { ThreadedAskStreamMessages, ThreadedAskStreamMessageTypes } from './messages';
import { QuestionInteractionType, QuestionPayload } from './shared';

export type ThreadedCommonState = {
  payload: QuestionPayload;
  apiUrl: string;
  interactionContext: QuestionInteractionType | null;
}

export type ThreadedAskStreamInitiatedState = ThreadedCommonState & {
  state: 'initiated';
  requestInitiatedTimestamp: number;
  sources: SourceView[];
  totalPotentialSources: number;
}

export type ThreadedAskStreamAnsweringState = ThreadedCommonState & {
  state: 'answering';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  sources: SourceView[];
  totalPotentialSources: number;
}

export type ThreadedAskStreamCompleteState = ThreadedCommonState & {
  state: 'complete';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
  sources: SourceView[];
  totalPotentialSources: number;
}

// This aborted state isn't created through events or handled by the reducer right now.
// When we get an abort, we're creating the aborted state and the rendering it like an active question.
export type ThreadedAskStreamAbortedState = ThreadedCommonState & {
  state: 'aborted';
  answer: string;
  sources: SourceView[];
  totalPotentialSources: number;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
}

export type ThreadedAskStreamErrorState = ThreadedCommonState & {
  state: 'error';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
  sources: SourceView[];
  totalPotentialSources: number;
}

export function createInitialState(payload: QuestionPayload, apiUrl: string, interactionContext: QuestionInteractionType | null): ThreadedAskStreamInitiatedState {
  const MAX_SOURCES = 10; // Keeps things sane if we never get a potential_sources_identified event
  return {
    state: 'initiated',
    payload,
    requestInitiatedTimestamp: Date.now(),
    sources: [],
    totalPotentialSources: MAX_SOURCES,
    apiUrl,
    interactionContext
  }
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}

function isMessageData(messageData: unknown): messageData is ThreadedAskStreamMessages {
  return isObject(messageData) && ThreadedAskStreamMessageTypes.has((messageData as ThreadedAskStreamMessages).type);
}

export const filter = (messageData: unknown): messageData is ThreadedAskStreamMessages => {
  return isMessageData(messageData);
};

export type ThreadedAskStreamState = ThreadedAskStreamInitiatedState | ThreadedAskStreamAnsweringState | ThreadedAskStreamCompleteState | ThreadedAskStreamErrorState | ThreadedAskStreamAbortedState;

type ThreadedExpectedMessages = ThreadedAskStreamMessages | EventSourceActions;

export function createAbortedStateFromExisting(state: ThreadedAskStreamState): ThreadedAskStreamAbortedState {
  return {
    ...state,
    state: 'aborted',
    sources: state.sources ?? [],
    answer: state.state !== 'initiated' ? state.answer : '',
    answerStreamingStartedTimestamp: state.state !== 'initiated' ? state.answerStreamingStartedTimestamp : 0,
    answerStreamingEndedTimestamp: Date.now()
  };
}

export function threadedStateReducer(state: ThreadedAskStreamState, action: ThreadedExpectedMessages): ThreadedAskStreamState {
  if (action.type === 'EventSourceError' || action.type === 'error') {
    return errorState(state);
  }

  if (state.state === 'initiated') {
    return initiatedStateReducer(state, action);
  }

  if (state.state === 'answering') {
    return answeringStateReducer(state, action);
  }

  return state;
}

function errorState(state: ThreadedAskStreamState): ThreadedAskStreamErrorState {
  const answer = state.state === 'answering'
    ? state.answer
    : 'Something went wrong. Please try again.';

  return {
    ...state,
    state: 'error',
    answer,
    answerStreamingStartedTimestamp: Date.now(),
    answerStreamingEndedTimestamp: Date.now(),
    requestInitiatedTimestamp: state.requestInitiatedTimestamp,
  };
}

function initiatedStateReducer(state: ThreadedAskStreamInitiatedState, action: ThreadedExpectedMessages): ThreadedAskStreamState {
  if (action.type === 'potential_sources_identified') {
    return {
      ...state,
      totalPotentialSources: action.sources.length
    }
  }

  if (action.type === 'source_identified') {
    const newSource = action.source;

    return {
      ...state,
      sources: [...state.sources, newSource].sort(sortSourceByDisplayType)
    }
  }

  if (action.type === 'answer_chunk') {
    // Moving from initiated -> answering
    return {
      ...state,
      state: 'answering',
      answer: action.data,
      answerStreamingStartedTimestamp: Date.now()
    }
  }

  return state;
}

function answeringStateReducer(state: ThreadedAskStreamAnsweringState, action: ThreadedExpectedMessages): ThreadedAskStreamState {
  if (action.type === 'answer_chunk') {
    return {
      ...state,
      answer: state.answer + action.data
    }
  }

  if (action.type === 'answer_complete') {
    return {
      ...state,
      state: 'complete',
      answer: state.answer,
      answerStreamingEndedTimestamp: Date.now()
    }
  }

  return state;
}
