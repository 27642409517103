import { SourceView } from '@/types';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import React, { useCallback } from 'react';
import TaxNotes from '../../../assets/svg/taxnotes.svg?react';

import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { RestrictedSourceInteractionContext } from '../types';

type ExternalSourceLinkProps = {
  contentType?: string;
  data: SourceView;
  additionalSources: boolean;
}

export const ExternalSourceLink = (props: ExternalSourceLinkProps) => {
  const {
    data,
    contentType,
    additionalSources
  } = props;
  const { trackSourceLinkClicked, trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();
  const isRestricted = !('content' in data);
  const handleTrackSourceLinkClicked = useCallback(() => {
    isRestricted
      ? trackRestrictedSourceOpenInNewTabClicked({ sourceType: data.displayType, sourceTitle: data.title, referredUrl: data.href, interactionContext: RestrictedSourceInteractionContext.VIEW_IN_TAX_NOTES, additionalSources })
      : trackSourceLinkClicked({ sourceTitle: data.title, sourceType: data.displayType, contentType, restricted: isRestricted, referredUrl: data.href, additionalSources });
  }, [data.id]);
  return (
    <div className="pt-6">
      <a className="text-blue-400 hover:underline underline-offset-4" href={data.href} target="_blank" rel="noreferrer noopener" onClick={handleTrackSourceLinkClicked}>
        { isRestricted ?
            <div className="flex gap-1 items-center">
              <span title="View in Taxnotes">View in</span><TaxNotes className="h-5" aria-hidden="true" />
              <ArrowTopRightOnSquareIcon className="w-4 h-4" aria-hidden="true" />
            </div> :
            <span title={data.href}>
              {data.href}
            </span>
        }
      </a>
    </div>
  );
}
