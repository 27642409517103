import { useQuery } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../api/ask-bluej-api';
import { useShowUkTopic } from '../feature-flag/features/use-show-uk-topic';

export function useStrategyQuery() {
  const { api } = useAskBluejApiContext();
  const showUkTopic = useShowUkTopic();

  return useQuery({
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryKey: ['strategies'],
    queryFn: async () => {
      const { strategies, topics } = await api.getStrategies();
      const strategiesList = strategies
        .filter(strategy => strategy.answerStrategyName !== 'error')
        .map(strategy => {
          const strategyTopic = topics.find(topic => topic.strategy === strategy.answerStrategyName);

          const displayName = strategyTopic ? `${strategy.answerStrategyName} (${strategyTopic.displayName})` : strategy.answerStrategyName;

          return {
            displayName,
            value: strategy.answerStrategyName
          }
        });

      return {
        strategiesList,
        topics: topics.filter(topic => {
          return !(!showUkTopic && topic.value === 'uk_corporate_tax');
        }),
      }

    }
  })
}

